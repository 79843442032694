import React from 'react';
import * as currencyFormatter from 'currency-formatter';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '~/components/loading/loading';
import Header from '~/components/header/index';
import Footer from '~/components/footer/index';
import NotFound from '~/components/not-found/index';

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { getMessage,system_lang, convertCentsToFloat, getParamUrl } from '~/assets/utils';
import { getProductsBySearch } from '~/assets/requests/product';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            products: [],
            searchs: [],
            attributes: [],
            message_load: 'loading_products',
            loaded: false,
            ads: {},
            page: 1,
            total_pages: 1,
            sidebarFilterStatus: false,
            filters_selected: [],
            loading_more: false,
            current_page: 1,
            itemsByPage: 20,
            hovers: []
        }
        this.userSession = () => {
            this.setState({user: this.props.user});
        }
        this.getProductsSearch = async () => {
            let response = await getProductsBySearch(getParamUrl('q'));
            if ( response.status === true ) {
                this.setState({
                    products: response.products,
                    ads: response.ads
                });
            }
            setTimeout(() => {
                this.setState({loaded: true, message_load: 'all_is_ready'});
            }, 2000);
        }
        /**
         * Carrega a imagem do produto
         * @param {*} product 
         * @param {*} product_id 
         */
        this.productImage = (product, product_id) => {
            try {
                if (this.state.hovers.includes(product_id)) {
                    return (product.images[1] || product.images[0]);
                }
                else {
                    return product.images[0];
                }
            }
            catch (error) {
                return false;
            }
        }
        /**
         * Remove o hover do produto
         * @param {*} product_id 
         */
        this.onProductLeve = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    const indexOf = this.state.hovers.findIndex(item => item === product_id);
                    if (indexOf >= 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.splice(indexOf, 1);
                        this.setState({hovers});
                    }
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * Remove o enter de um produto
         * @param {*} product_id 
         */
        this.onProductEnter = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    if (this.state.hovers.findIndex(item => item === product_id) < 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.push(product_id);
                        this.setState({hovers});
                    }
                }
                else {
                    const hovers = Object.assign([], this.state.hovers);
                    hovers.push(product_id);
                    this.setState({hovers});
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * render HTML
         */
        this.loadProducts = () => {
            let list_products = [];
            const { products, current_page, itemsByPage } = this.state;
            const itemAtual = current_page === 1 ? 0 : products.length - (products.length - (itemsByPage*current_page/2));
            const lastItem = itemAtual === 0 ? itemsByPage : (itemAtual + products.length);

            products.slice(itemAtual, lastItem).map((product, key) => {
                list_products.push(
                    <a href={process.env.REACT_APP_URL + '/product/' + product._id} className='search-product cursor-pointer' key={key} onClick={(e) => this.seeProduct(product._id, e)}>
                        <div className='search-product-img'
                            onMouseOver={() => this.onProductEnter(product._id)}
                            onMouseOut={() => this.onProductLeve(product._id)}>
                            <LazyLoadImage
                                alt={product.name}
                                height={152}
                                width={'auto'}
                                effect="blur"
                                src={this.productImage(product, product._id)} />
                        </div>
                        <div className='search-product-details'>
                            <div className='search-product-price pl-3 pr-3 pb-1 text-center'>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                            <div className='search-product-name pl-3 pr-3 pb-2 text-center'>{product.name}</div>
                        </div>
                    </a>
                );
                return true;
            });

            return list_products;
        }
        this.loadMoreProducts = (event) => {
            let element = event.target.scrollingElement;
            if (element.scrollHeight - element.scrollTop === element.clientHeight && this.state.loading_more === false) {
                this.getMoreProducts();
            }
        }
        this.onChangePage = async (page) => {
            await this.setState({current_page: page});
            this.loadProducts();
        }
    }

    async componentDidMount() {
        this.setState({search: getParamUrl('q')});
        
        this.getProductsSearch();
        document.addEventListener("scroll", this.loadMoreProducts);
    }

    render() {
        const { products, message_load, loaded, ads, search } = this.state;
        require('./css/search.css');
        return(
            <Loading message={message_load} loaded={loaded}>
                <div className={`container-search container-fluid mt-2 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                    <div className='row'>
                        <div className={`background-sidebar-searchs ${this.state.sidebarFilterStatus === true && 'show'}`} onClick={this.changeSidebarFilterStatus}></div>
                        <main className='col-lg-10 col-xl-11 m-auto col-12'>
                            <div className='wrapper-content-search'>
                                <div className='search-title mb-3'>
                                    <div className='d-flex flex-wrap flex-md-nowrap container-filter-order'>
                                        <h1 className='content-search-title h3'><strong>{getMessage('results_by')}:</strong> {search}</h1>
                                    </div>
                                </div>
                                {products.length === 0 && <NotFound icon_url={require('../../components/not-found/img/img_empty_cart.png')} description='none_product_search'/>}
                                {Object.values(ads).length > 0 &&
                                <div className='search-bn mb-4'>
                                    <a className='d-block' href={ads.url || '#'}>
                                        <img className='img-fluid w-100' src={ads.banner} alt={ads.name} />
                                    </a>
                                </div>}
                                {products.length > 0 && <div className='w-100'><div className='d-flex flex-wrap'>{this.loadProducts()}</div></div>}
                                {products.length > 0 && <div className='w-100'><Pagination onChange={this.onChangePage} current={this.state.current_page} pageSize={20} total={products.length} /></div>}
                            </div>
                        </main>
                    </div>
                </div>
                <Footer />
            </Loading>
        )
    }
}
export default withRouter(Search);