import React from 'react';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Skeleton from 'react-loading-skeleton';

export default class LoadingHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        const state = Object.assign({}, this.state);
        for (let key in this.props) {
            if (state.hasOwnProperty(key)) {
                state[key] = this.props[key];
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loaded !== this.props.loaded) {
            this.setState({loaded: this.props.loaded});
        }
    }

    render() {
        const { loaded } = this.state;
        if (loaded === false) {
            return(
                <div>
                    <div className='container-page container-fluid show'>
                        <main>
                            <section className='group-products-content mb-3 mt-0 mt-md-3 ml-auto mr-auto'>
                                <div className='group-products-title'><Skeleton /></div>
                                <div className='skeleton-group-banner'>
                                    <Skeleton />
                                </div>
                                <div className='row justify-content-md-center row-skeleton ml-auto mr-auto'>
                                    <Skeleton height='274px' />
                                    <Skeleton height='274px' />
                                    <Skeleton height='274px' />
                                    <Skeleton height='274px' />
                                    <Skeleton height='274px' />
                                </div>
                            </section>
                        </main>
                    </div>
                    <Footer />
                </div>
            )
        }
        else {
            return this.props.children;
        }
    }
}