import React from 'react';
import { withRouter } from 'react-router-dom';
import { getMessage } from '~/assets/utils';

import './css/not_found.css';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'not_found',
            description: 'error_page_404',
            button_text: 'go_to_home',
            icon_url: require('./img/img_empty_cart.png'),
            redirect: '/',
            button_status: true
        }
        this.goToPage = () => {
            this.props.history.push(this.state.redirect);
        }
    }

    componentDidMount() {
        const state = Object.assign({}, this.state);
        if (this.props.title) state.title = this.props.title;
        if (this.props.description) state.description = this.props.description;
        if (this.props.button_text) state.button_text = this.props.button_text
        if (this.props.icon_url) state.icon_url = this.props.icon_url;
        if (this.props.redirect) state.redirect = this.props.redirect;
        if ( typeof this.props.button_status !== undefined ) state.button_status = this.props.button_status;

        this.setState(state);
    }

    render() {
        const { title, description, button_text, icon_url, button_status } = this.state
        return(
            <div className='error-wrapper'>
                <div className='man-icon' style={{background: "url("+icon_url+") center center no-repeat"}}></div>
                <h3 className='title'>{getMessage(title)}</h3>
                <p className='info'>{getMessage(description)}</p>
                {button_status === true && <button type='button' className='cursor-pointer home-btn' onClick={this.goToPage}>{getMessage(button_text)}</button>}
            </div>
        )
    }
}

export default withRouter(NotFound);