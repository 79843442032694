import React from 'react';
import * as currencyFormatter from 'currency-formatter';
import { withRouter } from 'react-router-dom';

import Loading from '~/components/loading/loading';
import Footer from '~/components/footer/index';
import NotFound from '~/components/not-found/index';

import { getMessage,system_lang, convertCentsToFloat } from '~/assets/utils';
import { getProductsByCategory, getCategories } from '~/assets/requests/firebase';
import LoadingCategory from './loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category_id: '',
            category_name: '',
            products: [],
            categories: [],
            attributes: [],
            message_load: 'loading_products',
            loaded: false,
            ads: {},
            page: 1,
            total_pages: 1,
            sidebarFilterStatus: false,
            filters_selected: [],
            hovers: []
        }

        this.getCategories = async () => {
            let response = await getCategories(this.state.category_id);
            if ( response.status === true ) {
                response.categories = response.categories.filter(item=>item._id !== this.state.category_id);
                this.setState({categories: response.categories})
            }
            setTimeout(() => {
                this.setState({loaded: true, message_load: 'all_is_ready'});
            }, 2000);
        }
        this.getProductsCategory = async () => {
            this.setState({category_name: ''})
            let response = await getProductsByCategory(this.state.category_id);
            if ( response.status === true ) {
                this.setState({
                    products: response.products,
                    ads: response.ads,
                    category_name: response.category.name,
                    attributes: response.attributes
                });
            } else {
                this.setState({
                    products: [],
                    ads: [],
                    category_name: '',
                    attributes: []
                });
            }
            setTimeout(() => {
                this.setState({message_load: 'loading_categories'});
                this.getCategories();
            }, 2000);
        }
        this.seeProduct = (product_id, e) => {
            e.preventDefault();
            this.props.history.push('/product/' + product_id);
            this.getProductsCategory();
        }
        this.openCategory = async (category_id, e) => {
            e.preventDefault();
            this.props.history.push('/category/' + category_id);
            await this.checkUrl();
            this.setState({loaded: false, message_load: 'loading_products'});
            this.getProductsCategory();
        }
        /**
         * Escolhe os atributos para filtrar os produtos
         */
        this.chooseAttribute = (children_id, attribute_id) => {
            try {
                const { filters_selected } = this.state;
                if ( filters_selected.length > 0 ) {
                    let indexOfAtt = filters_selected.findIndex(item=>item.attribute_id === attribute_id && item.children_id === children_id);
                    if (indexOfAtt >= 0) {
                        filters_selected.splice(indexOfAtt, 1);
                    } else {
                        filters_selected.push({
                            attribute_id: attribute_id,
                            children_id: children_id
                        });
                    }
                } else {
                    filters_selected.push({
                        attribute_id: attribute_id,
                        children_id: children_id
                    });
                }
                this.setState({filters_selected, sidebarFilterStatus: false});
            }
            catch(error) {
                return false;
            }
        }
        this.checkUrl = async () => {
            let url = window.location.pathname.split('/');
            if (url.length < 3 || url[2].length === 0) {
                this.props.history.push('/404');
            }
            this.setState({category_id: url[2]});
        }
        this.changeSidebarFilterStatus = () => {
            this.setState({sidebarFilterStatus: !this.state.sidebarFilterStatus});
        }
        /**
         * Carrega a imagem do produto
         * @param {*} product 
         * @param {*} product_id 
         */
        this.productImage = (product, product_id) => {
            try {
                if (this.state.hovers.includes(product_id)) {
                    return (product.images[1] || product.images[0]);
                }
                else {
                    return product.images[0];
                }
            }
            catch (error) {
                return false;
            }
        }
        /**
         * Remove o hover do produto
         * @param {*} product_id 
         */
        this.onProductLeve = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    const indexOf = this.state.hovers.findIndex(item => item === product_id);
                    if (indexOf >= 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.splice(indexOf, 1);
                        this.setState({hovers});
                    }
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * Remove o enter de um produto
         * @param {*} product_id 
         */
        this.onProductEnter = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    if (this.state.hovers.findIndex(item => item === product_id) < 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.push(product_id);
                        this.setState({hovers});
                    }
                }
                else {
                    const hovers = Object.assign([], this.state.hovers);
                    hovers.push(product_id);
                    this.setState({hovers});
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * render HTML
         */
        this.loadProducts = () => {
            let list_products = [];
            const { products, filters_selected } = this.state;
            let prevProducts = [];
            if (filters_selected.length > 0) {
                products.map(product => {
                    let has = false;

                    product.attributes.forEach(attribute => {
                        let indexOf = filters_selected.findIndex(filter => filter.attribute_id === Object.keys(attribute.values)[0] && filter.children_id === Object.values(attribute.values)[0]);
                        if (indexOf >= 0 && attribute.values.quantity > 0) has = true;
                    });
                    if (has === true) {
                        prevProducts.push(product);
                    } else {
                        return false;
                    }
                })
            } else {
                prevProducts = products;
            }
            prevProducts.map((product, key) => {
                list_products.push(
                    <a href={process.env.REACT_APP_URL + '/product/' + product._id} className='category-product cursor-pointer' key={key} onClick={(e) => this.seeProduct(product._id, e)}>
                        <div className='category-product-img'
                            onMouseOver={() => this.onProductEnter(product._id)}
                            onMouseOut={() => this.onProductLeve(product._id)}>
                            <LazyLoadImage
                                alt={product.name}
                                height={152}
                                width={'auto'}
                                effect="blur"
                                src={this.productImage(product, product._id)} />
                        </div>
                        <div className='category-product-details'>
                            <div className='category-product-price pl-3 pr-3 pb-1 text-center'>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                            <div className='category-product-name pl-3 pr-3 pb-2 text-center'>{product.name}</div>
                        </div>
                    </a>
                );
                return true;
            });

            return list_products;
        }
        /**
         * Renderiza o html que lista os atributos
         */
        this.loadAttributes = () => {
            const { attributes, filters_selected } = this.state;
            let list_attributes = [];
            attributes.map((attribute, key) => {
                list_attributes.push(
                    <ul className='sidebar-list' key={key}>
                        <li className='title-sidebar-list'>
                            <h2 className='title-sidebar'>{attribute.name}</h2>
                        </li>
                        {
                        attribute.children.length > 0 &&
                        attribute.children.map((children, key) => {
                            let selected = false;
                            if ( filters_selected.length > 0 ) {
                                let indexOfAtt = filters_selected.findIndex(item=>item.attribute_id === attribute._id && item.children_id === children._id);
                                if (indexOfAtt >= 0) selected = true;
                            }
                            return(
                                <li className={`sidebar-item ${selected === true && 'active'}`} onClick={()=>this.chooseAttribute(children._id, attribute._id)} key={key}>
                                    <span className='d-inline-block'>{children.name}</span>
                                </li>
                            )
                        })
                        }
                    </ul>
                );
                return true;
            });
            return list_attributes;
        }
        this.loadCategories = () => {
            let list_categories = [];
            const { categories } = this.state;
            categories.map((category, key) => {
                list_categories.push(
                    <li className='sidebar-item' key={key}>
                        <a href={process.env.REACT_APP_URL + "/category/" + category._id} onClick={(e)=>this.openCategory(category._id, e)} className='d-inline-block'>{category.name}</a>
                    </li>
                );
                return true;
            });
            return list_categories;
        }
        /** end RENDER HTML */
    }
    async componentDidMount() {
        document.querySelector('body').classList.remove('scroll-false');
        let url = window.location.pathname.split('/');
        if (url.length < 3 || url[2].length === 0) {
            this.props.history.push('/404');
        }
        await this.setState({category_id: url[2]});
        this.getProductsCategory();
    }

    render() {
        try {
            const { products, categories, loaded, ads, category_name, attributes } = this.state;
            require('./css/category.css');
            return(
                <>
                    <div className={`container-category container-fluid mt-2 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                        <div className='row'>
                            <div className={`background-sidebar-categories ${this.state.sidebarFilterStatus === true && 'show'}`} onClick={this.changeSidebarFilterStatus}></div>
                            <aside className={`sidebar-categories col-lg-3 col-xl-2 ${this.state.sidebarFilterStatus === true && 'show'}`}>
                                <div className='open-sidebar-categories' onClick={this.changeSidebarFilterStatus}>
                                    <i className='icon-close-sidebar  medium-icon'></i>
                                </div>
                                <div className='sidebar-category-title mb-2'>{getMessage('filter_search')}</div>
                                <div className='h-100 overflow-y-auto pl-2 pr-2'>
                                    <ul className='sidebar-list sidebar-list-category'>
                                        <li className='title-sidebar-list'>
                                            <h2 className='title-sidebar'>{getMessage('categories')}</h2>
                                        </li>
                                        {categories.length === 0 && <Skeleton width='100%' height='25px' />}
                                        {categories.length === 0 && <Skeleton width='100%' height='25px' />}
                                        {categories.length === 0 && <Skeleton width='100%' height='25px' />}
                                        {this.loadCategories()}
                                    </ul>
                                    {this.loadAttributes()}
                                    {attributes.length === 0 && <Skeleton width='100%' height='25px' />}
                                    {attributes.length === 0 && <Skeleton width='100%' height='25px' />}
                                    {attributes.length === 0 && <Skeleton width='100%' height='25px' />}
                                </div>
                            </aside>
                            <main className='col-lg-9 col-xl-10'>
                                <div className='wrapper-content-category'>
                                    <div className='category-title mb-3'>
                                        <div className='d-flex flex-wrap flex-md-nowrap container-filter-order'>
                                            <h1 className='content-category-title h3'>{category_name || <Skeleton style={{marginLeft: 0}} width='150px' height='25px' />}</h1>
                                        </div>
                                        <div className='open-sidebar-filter d-block d-lg-none w-100 mt-3 ml-auto mr-auto' onClick={this.changeSidebarFilterStatus}>{getMessage('filter_search')}</div>
                                    </div>
                                    <LoadingCategory loaded={loaded}>
                                        {products.length === 0 && <NotFound icon_url={require('../../components/not-found/img/img_empty_cart.png')} description='none_product_category'/>}
                                        {Object.values(ads).length > 0 &&
                                        <div className='category-bn mb-4'>
                                            <a className='d-block' href={ads.url || '#'}>
                                                <img className='img-fluid w-100' src={ads.banner} alt={ads.name} />
                                            </a>
                                        </div>}
                                        {products.length > 0 && <div className='w-100'><div className='d-flex flex-wrap'>{this.loadProducts()}</div></div>}
                                    </LoadingCategory>
                                </div>
                            </main>
                        </div>
                    </div>
                    <Footer />
                </>
            )
        }
        catch(error) {
            return <div className='text-center p-5 mt-5'>{error.message}</div>
        }
    }
}
export default withRouter(Category);