let defaultFirebase = require('~/firebase/index.js');
const firebase = defaultFirebase.default;
const { getFirebaseData } = require('~/firebase/functions');
/**
 * Complete o cadastro de um usuário, iniciado pela função registerUser
 * @param {*} user_id - id gerado na função registerUser
 * @param {*} user_data - dados dos usuário, recebidos pela função registerUser
 */
export const completeRegisterUser = async (user_id, user_data) => {
    if (!user_id || !user_data) {
        return {
            status: false,
            message: 'fields_needed'
        }
    }
    try {
        await firebase.database().ref('users/' + user_id).set({
            name: user_data.first_name,
            surname: user_data.last_name,
            email: user_data.email,
            phone: user_data.phone,
            createdAt: new Date().getTime(),
            status: 1
        });
        localStorage.setItem('user_id', user_id);
        return {
            status: true,
            user_id
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Cria uma novo usuário
 * @param {*} user_data - dados do usuário
 */
export const registerUser = async (user_data) => {
    try {
        const { email, password } = user_data;
        if ( user_data.first_name.length < 2 || user_data.last_name.length < 2) {
            return {
                status: false,
                message: 'full_name_is_required'
            }   
        }
        let response = await firebase.auth().createUserWithEmailAndPassword(email, password);
        if ( response && response.user ) {
            let response_u = await completeRegisterUser(response.user.uid, user_data);
            if ( response_u.status === true ) {
                return {
                    status: true
                }
            } else {
                return {
                    status: false,
                    message: response_u.message
                }
            }
        } else {
            return {
                staut: false,
                message_code: 'request_failed'
            }
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Autentica um usuário existente
 * @param {*} userData 
 */
export const loginUser = async (userData) => {
    try {
        const { email, password } = userData;
        let response = await firebase.auth().signInWithEmailAndPassword(email, password);
        if ( response && response.user.uid ) {
            localStorage.setItem('user_id', response.user.uid);
            return {
                status: true
            }
        } else {
            return {
                status: false,
                message: 'request_failed'
            }
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Envia um email com o link para a nova senha
 * @param {*} email - email da conta
 */
export const forgotPassword = async (email) => {
    try {
        let actionCodeSettings = {
            url: process.env.REACT_APP_URL + '/login',
            handleCodeInApp: false
        };
        await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
        return {
            status: true,
            message: 'emal_recovery_sended'
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Define uma nova senha para o usuário
 * @param {*} password - senha digitada
 * @param {*} user - usuário à ser alterado
 */
export const recoveryPassword = async (password, user) => {
    try {
        return {
            status: false,
            message: 'request_failed'
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna os dados do usuário logado
 */
export const getUserData = async () => {
    try {
        let user_id = localStorage.getItem('user_id');
        if ( !user_id ) {
            return {
                status: false,
                message: 'user_not_logged'
            }
        }
        
        let response = await getFirebaseData('users/' + user_id);
        if ( response.status === true ) {
            response.data.user_id = user_id;
            return {
                status: true,
                data: response.data
            }
        } else {
            return {
                status: false,
                message: 'request_failed'
            }
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}