import React from 'react';
import * as currencyFormatter from 'currency-formatter';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingGroup from './loading';
import Header from '~/components/header/index';
import Footer from '~/components/footer/index';
import NotFound from '~/components/not-found/index';

import { system_lang, convertCentsToFloat } from '~/assets/utils';
import { getProductsByGroup, getProductsByGroupLast } from '~/assets/requests/groups';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Group extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group_id: '',
            group_name: '',
            products: [],
            groups: [],
            attributes: [],
            message_load: 'loading_products',
            loaded: false,
            ads: {},
            page: 1,
            total_pages: 1,
            sidebarFilterStatus: false,
            filters_selected: [],
            loading_more: false,
            group_banner: null,
            hovers: []
        }
        this.userSession = () => {
            this.setState({user: this.props.user});
        }
        this.getProductsGroup = async () => {
            try {
                let response = await getProductsByGroup(this.state.group_id, 25);
                if ( response.status === true ) {
                    this.setState({
                        products: response.products,
                        ads: response.ads,
                        group_name: response.group.name,
                        attributes: response.attributes,
                        group_banner: response.group.banner || null
                    });
                } else {
                    this.setState({
                        products: [],
                        ads: [],
                        group_name: '',
                        attributes: []
                    });
                }
                setTimeout(() => {
                    this.setState({loaded: true, message_load: 'all_is_ready'});
                }, 2000);
            }
            catch(error) {
                return;
            }
        }
        this.getMoreProducts = async () => {
            this.setState({loading_more: true});
            let response = await getProductsByGroupLast(this.state.group_id, this.state.products.length);
            if ( response.status === true ) {
                let products = Object.assign([], this.state.products);
                response.products.forEach(product => {
                    products.push(product);
                });
                this.setState({
                    products: products,
                    ads: response.ads,
                    group_name: response.group.name,
                    attributes: response.attributes,
                    loading_more: false
                });
            }
        }
        /**
         * Carrega a imagem do produto
         * @param {*} product 
         * @param {*} product_id 
         */
        this.productImage = (product, product_id) => {
            try {
                if (this.state.hovers.includes(product_id)) {
                    return (product.images[1] || product.images[0]);
                }
                else {
                    return product.images[0];
                }
            }
            catch (error) {
                return false;
            }
        }
        /**
         * Remove o hover do produto
         * @param {*} product_id 
         */
        this.onProductLeve = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    const indexOf = this.state.hovers.findIndex(item => item === product_id);
                    if (indexOf >= 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.splice(indexOf, 1);
                        this.setState({hovers});
                    }
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * Remove o enter de um produto
         * @param {*} product_id 
         */
        this.onProductEnter = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    if (this.state.hovers.findIndex(item => item === product_id) < 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.push(product_id);
                        this.setState({hovers});
                    }
                }
                else {
                    const hovers = Object.assign([], this.state.hovers);
                    hovers.push(product_id);
                    this.setState({hovers});
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * render HTML
         */
        this.loadProducts = () => {
            let list_products = [];
            const { products } = this.state;
            products.map((product, key) => {
                list_products.push(
                    <a href={process.env.REACT_APP_URL + '/product/' + product._id} className='group-product cursor-pointer' key={key} onClick={(e) => this.seeProduct(product._id, e)}>
                        <div className='group-product-img'
                            onMouseOver={() => this.onProductEnter(product._id)}    
                            onMouseOut={() => this.onProductLeve(product._id)}>
                            <LazyLoadImage
                                alt={product.name}
                                height={152}
                                effect="blur"
                                width={'auto'}
                                src={this.productImage(product, product._id)} />
                        </div>
                        <div className='group-product-details'>
                            <div className='group-product-price pl-3 pr-3 pb-1 text-center'>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                            <div className='group-product-name pl-3 pr-3 pb-2 text-center'>{product.name}</div>
                        </div>
                    </a>
                );
                return true;
            });

            return list_products;
        }
        this.loadMoreProducts = (event) => {
            let element = event.target.scrollingElement;
            if (element.scrollHeight - element.scrollTop === element.clientHeight && this.state.loading_more === false) {
                this.getMoreProducts();
            }
        }
    }

    async componentDidMount() {
        let url = window.location.pathname.split('/');
        if (url.length < 3 || url[2].length === 0) {
            this.props.history.push('/404');
        }
        await this.setState({group_id: url[2]});

        
        this.getProductsGroup();
        document.addEventListener("scroll", this.loadMoreProducts);
    }

    render() {
        const { products, loaded, ads, group_name, group_banner } = this.state;
        require('./css/group.css');
        return(
            <LoadingGroup loaded={loaded}>
                <div className={`container-group container-fluid ${loaded === true && 'show'}`}>
                    <div className={`background-sidebar-groups ${this.state.sidebarFilterStatus === true && 'show'}`} onClick={this.changeSidebarFilterStatus}></div>
                    <main id="panel">
                        <section className='group-products-content'>
                            {
                            group_banner &&
                            <div className='group-products-bn'>
                                <div className='img-fluid w-100 group-banner-img' style={{backgroundImage: 'url(' + group_banner + ')'}}></div>
                            </div>
                            }
                        </section>
                        <h1 className='content-group-title h4 pb-2 pt-2'>{group_name}</h1>
                        <div className='wrapper-content-group'>
                            {products.length === 0 && <NotFound icon_url={require('../../components/not-found/img/img_empty_cart.png')} description='none_product_group'/>}
                            {Object.values(ads).length > 0 &&
                            <div className='group-bn mb-4'>
                                <a className='d-block' href={ads.url || '#'}>
                                    <img className='img-fluid w-100' src={ads.banner} alt={ads.name} />
                                </a>
                            </div>}
                            {products.length > 0 && <div className='w-100'><div className='d-flex flex-wrap' style={{marginLeft: "-5px"}}>{this.loadProducts()}</div></div>}
                        </div>
                    </main>
                </div>
                <Footer />
            </LoadingGroup>
        )
    }
}
export default withRouter(Group);