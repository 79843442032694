import React from 'react';
import Footer from '~/components/footer';
import Loading from '~/components/loading/loading';
import NotFound from '~/components/not-found/index';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as currencyFormatter from 'currency-formatter';
import InputMask from 'react-input-mask';
import {Alert, Button, Spinner} from 'reactstrap';

import { getMessage, system_lang, convertCentsToFloat } from '~/assets/utils';
import {
  getProductsCart, removeAllProductFromCart, removeProductFromCart, addProductToCart, getAddressDelivery, createOrder,
  getCupoms
} from '../../assets/requests/product';
import { systemSettings } from '~/firebase/functions';

class ShoppingCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          message_load: 'loading_products',
          loaded: false,
          products: [],
          attributes: [],
          total_price: 0,
          zip_code: '',
          orders_available: false,
          fare_price: 0,
          loadingFare: false,
          deliveryMode: 0,
          loadingCupom: false,
          cupom: '',
          porcentage: undefined,
          currency: 0,
          cupom_error: '',
          loading: false
        }

        this.handleChange = async (e) => {
          try {
            const state = Object.assign({}, this.state);
            state[e.target.name] = e.target.value;
            await this.setState(state);
            if (parseInt(e.target.value) === 1) {
              let response = {
                data: {}
              };

              if ( response.status === true || this.state.deliveryMode === '1' ) {
                response.data.total_price = this.calcTotalPrice(this.state.total_price);
                response.data.zip_code = state.zip_code ? state.zip_code : '';
                response.data.fare_price = 0;
                response.data.national_identification = '';
                response.data.national_identification_email = '';
                response.data.national_identification_name = '';
                response.data.deliveryMode = 1;
                response.data.orders_available = true;
                this.props.dispatch({type: 'SET_FREIGHT_DATA', data: response.data, orders_available: true})
                this.setState({fare_price: 0, loadingFare: false});
              } else {
                this.handleChangeZip(state.zip_code);
              }
            }
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.calcTotalPrice = (total_price) => {
          try {
            let price = 0;
            let discontoPorValor = this.state.currency;
            let discontoPorPorcentagem = this.state.porcentage;

            if (discontoPorValor > 0) {
              price = total_price - discontoPorValor;
            } else if (discontoPorPorcentagem > 0) {
              const discount = (total_price * discontoPorPorcentagem) / 100;
              price = total_price - discount;
            } else {
              price = total_price;
            }

            price = price > 0 ? price : 0;

            return price;
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.handleChangeZip = async (zip_code) => {
          try {
            this.setState({loadingFare: true});
            const { deliveryMode } = this.state;
            let orders_available = false;
            let fare_price = 0;
            let data_order = {};
            zip_code = zip_code.toString();
            if ( zip_code.match(/\d/g) && zip_code.match(/\d/g).join('').length === 8 ) {
              let response = await getAddressDelivery(zip_code.match(/\d/g).join(''));
              if ( response.status === true ) {
                fare_price = convertCentsToFloat(response.data.farePrice);
                if (parseInt(this.state.deliveryMode) === 1) {
                  fare_price = 0;
                }
                response.data.deliveryMode = this.state.deliveryMode;
                orders_available = true;
                response.data.total_price = this.calcTotalPrice(this.state.total_price);
                response.data.zip_code = zip_code.match(/\d/g).join('');
                response.data.fare_price = fare_price;
                response.data.national_identification = '';
                response.data.national_identification_email = '';
                response.data.national_identification_name = '';
                this.props.dispatch({type: 'SET_FREIGHT_DATA', data: response.data})
              }
            }
            this.setState({zip_code, orders_available, fare_price, data_order, loadingFare: false, loaded: true});
          }
          catch(error) {
            this.setState({loadingFare: false});
            this.props.alert.error(error.message);
            return;
          }
        }
        this.handleCupom = (event) => {
          const value = event.target.value;

          this.setState({cupom: value});
        }
        this.handleChangeCupom = async (cupom) => {
          try {
            let cupom_error = '';
            if (this.state.loadingCupom === true) return false;
            this.setState({cupom})
            if (cupom.toString().length >= 3) {
              let response = await getCupoms();
              if (response.status === true) {
                const discounts = response.data;
                let indexOf = discounts.findIndex(item=>item.code === cupom && item.status === 1);
                if (indexOf >= 0 && (discounts[indexOf].used < discounts[indexOf].quantity) && (discounts[indexOf].dateEnd > new Date().getTime())) {
                  if (discounts[indexOf].valueCurrency > 0) {
                    this.setState({currency: discounts[indexOf].valueCurrency, porcentage: 0, loadingCupom: false, loading: true});
                    setTimeout(() => {
                    this.setState({ loading: false });
                    }, 1000);
                  } else if (discounts[indexOf].valuePorcentage > 0) {
                    this.setState({porcentage: discounts[indexOf].valuePorcentage, currency: 0, loadingCupom: false, loading: true});
                    setTimeout(() => {
                      this.setState({ loading: false });
                    }, 1000);
                  }
                  return true;
                } else {
                  cupom_error = 'cupom_expired';
                }
              } else {
                cupom_error = response.message;
              }
              this.setState({loadingCupom: false, currency: 0, porcentage: 0, cupom_error, loading: true});
            } else {
              this.setState({loadingCupom: false, currency: 0, porcentage: 0, cupom_error, loading: true});
            }

            setTimeout(() => {
              this.setState({ loading: false });
            }, 1000);
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.getProductsCart = async () => {
          try {
            let response = await getProductsCart();
            if (response.status === true) {
              this.setState({ products: response.products, attributes: response.attributes, total_price: response.total_price }, () => {
                  this.handleChange({
                      target: {
                          name: 'deliveryMode',
                          value: '0'
                      }
                  })
              });
          }
          this.setState({ message_load: 'all_is_ready', loaded: true });
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.removeAll = async (product_id, attribute_id, attribute_value, product_key) => {
          try {
            let productData = {
              product_id,
              attribute_id,
              attribute_value
            }
            let total_price = this.state.total_price - (this.state.products[product_key].price || 0);
            let response = await removeAllProductFromCart(productData);
            if (response.status === true) {
              const products = Object.assign([], this.state.products);
              products.splice(product_key, 1);
              if (products.length === 0) total_price = 0;
              this.setState({products, total_price});
            }
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.subtractProduct = async (product_key) => {
          try {
            const products = Object.assign([], this.state.products);
            let product = products[product_key];
            let quantity = product.quantity;
            if ( quantity > 1 ) {
              let productData = {
                product_id: product._id,
                attribute_id: product.attribute_selected._id,
                attribute_value: product.attribute_selected.value,
              }
              let response = await removeProductFromCart(productData);
              if ( response.status === true ) {
                product.quantity -= 1;
                products[product_key] = product;
                let total_price = this.state.total_price - (this.state.products[product_key].price || 0);
                this.setState({products, total_price});
              }
            } else if ( quantity === 1 ) {
              this.removeAll(product._id, product.attribute_selected._id, product.attribute_selected.value, product_key);
            }
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.addProduct = async (product_key) => {
          try {
            const products = Object.assign([], this.state.products);
            let product = products[product_key];
            let productQuantity = undefined;
            product.attributes.forEach((atributo, atributoChave) => {
              Object.keys(atributo.values).forEach(item => {
                if (item !== 'quantity' && item === product.attribute_selected._id && atributo.values[item] === product.attribute_selected.value) {
                  productQuantity = product.attributes[atributoChave].values.quantity || 0;
                }
              });
            });
            if (productQuantity === product.quantity) {
              return false;
            }
            let productData = {
              product_id: product._id,
              attribute_id: product.attribute_selected._id,
              attribute_value: product.attribute_selected.value,
            }
            let response = await addProductToCart(productData);
            if ( response.status === true ) {
              product.quantity += 1;
              products[product_key] = product;
              let total_price = this.state.total_price + (this.state.products[product_key].price || 0);
              this.setState({products, total_price});
            }
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.seeProduct = (product_id, e) => {
          try {
            e.preventDefault();
            this.props.history.push('/product/' + product_id);
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.loadProduct = () => {
          try {
            let products_list = [];
            const { products, attributes } = this.state
            products.map( (product, key) => {
              let indexOfAttributeSelected = attributes.findIndex(item=>item._id === product.attribute_selected._id);
              let attribute_name;
              if ( indexOfAttributeSelected >= 0 ) {
                let indexOfChildren = attributes[indexOfAttributeSelected].children.findIndex(item => item._id === product.attribute_selected.value);
                if (indexOfChildren >= 0) attribute_name = attributes[indexOfAttributeSelected].children[indexOfChildren].name;
              }
              products_list.push(
                <div className='cart-product row mb-5' key={key}>
                  <div className='col-12 col-md-6 mb-4 md-md-0'>
                    <div className='media m-auto d-flex flex-wrap'>
                      <a className='thumbnail pull-left d-block d-lg-inline-block col-12 col-lg-3 text-center' href={process.env.REACT_APP_URL + '/product/' + product._id} onClick={(e)=> this.seeProduct(product._id, e)}>
                        <img className='media-object img-fluid' alt={process.env.REACT_APP_NAME} src={product.images[0]} />
                      </a>
                      <div className='media-body col-12 col-lg-9 text-center text-lg-left'>
                        <h4 className='media-heading'>
                          <a href={process.env.REACT_APP_URL + '/product/' + product._id} onClick={(e)=> this.seeProduct(product._id, e)}>{product.name}</a>
                        </h4>
                        <div className='product-details'>
                          <span>{getMessage('details')}:</span>
                          <div className='name-attribute'>{indexOfAttributeSelected >= 0 && attributes[indexOfAttributeSelected].name} {attribute_name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='row align-items-center'>
                      <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-auto order-sm-3 order-3 order-md-3 order-lg-3 order-xl-1 text-center mb-3 mb-xl-0'>
                        <div className='d-flex flex-nowrap buttons-cart-action justify-content-center' style={{marginLeft: '-15px'}}>
                          <button type='button' className='btn btn-default' onClick={() => this.subtractProduct(key)}>
                            <i className='icon-minus'></i>
                          </button>
                          <span className='total'>{product.quantity}</span>
                          <button type='button' className='btn btn-default' onClick={() => this.addProduct(key)}>
                            <i className='icon-add-plus'></i>
                          </button>
                        </div>
                      </div>
                      <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2 mb-lg-2 mb-3 mb-xl-0 text-center'>
                        <span className='d-inline'>{getMessage('price')}: </span>
                        <strong>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</strong>
                      </div>
                      <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-2 order-sm-2 order-md-2 order-lg-2 order-xl-3 mb-lg-2 mb-3 mb-xl-0 text-center'>
                        <span className='d-inline'>{getMessage('total')}: </span>
                        <strong>{product.price && currencyFormatter.format((convertCentsToFloat(product.price)*product.quantity), { locale: system_lang })}</strong>
                      </div>
                      <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-auto order-4'>
                        <button type='button' className='btn btn-danger w-100' onClick={() => this.removeAll(product._id, product.attribute_selected._id, product.attribute_selected.value, key)}>{getMessage('remove_from_cart')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
              return true;
            });
            return products_list;
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.goToHome = () => {
          try {
            this.props.history.push('/');
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.userSession = () => {
          this.setState({user: this.props.user});
        }
        this.seePayment = async () => {
          try {
            if (this.state.deliveryMode === "0" && this.state.zip_code === "") {
              this.props.alert.show('Informe seu CEP!');
              return false;
            }
            localStorage.removeItem('order_id');
            this.props.history.push('/payment');
          }
          catch(error) {
            this.props.alert.error(error.message);
            return;
          }
        }
        this.verifyCupom = () => {
          const {porcentage} = this.state;

          if (porcentage === 0) {
            return <Alert color="warning" className='mt-2'>Cupom inválido!</Alert>
          } else if (porcentage > 0) {
            return <Alert color="success" className='mt-2'>Cupom aplicado com sucesso!</Alert>
          }
        }
    }
    

    componentDidMount() {
        document.querySelector('body').classList.remove('scroll-false');
        this.userSession();
        this.getProductsCart();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.logged !== this.props.logged) {
          this.setState({
              logged: this.props.logged
          })
      }
  }

    render() {
      try {
        require('./css/cart.css');
          const { deliveryMode, loading, message_load, loaded, products, total_price, zip_code, orders_available, fare_price, loadingFare, loadingCupom, cupom, cupom_error, porcentage } = this.state;

          return (
            <Loading message={message_load} loaded={loaded}>
              <div
                className={`cart-container container-fluid mt-5 pt-md-4 pb-4 mb-5 ${
                  loaded === true && "show"
                }`}
              >
                <main className="h-100">
                  <div className="wrapper-table-cart">
                    {products.length > 0 && this.loadProduct()}
                    {products.length === 0 && (
                      <NotFound
                        title="none_product_added"
                        description="none_product_in_cart"
                      />
                    )}
                    {products.length > 0 && false && (
                      <div className="row mb-4 justify-content-end">
                        <div className="col-auto h6 text-left">
                          {getMessage("cupom_shopping")}:{" "}
                        </div>
                        <div className="col-auto">
                          <input
                            type="text"
                            className="input-zip-cart"
                            value={cupom}
                            onChange={this.handleCupom}
                          />
                          {loadingCupom === true && (
                            <div className="ring-2">
                              <div className="ball-holder">
                                <div className="ball"></div>
                              </div>
                            </div>
                          )}
                          <Button
                            disabled={loading}
                            type="button"
                            className="btn btn-success"
                            style={{ height: "35px" }}
                            onClick={() => this.handleChangeCupom(cupom)}
                          >
                            {loading === true ? (
                              "Aplicando..."
                            ) : (
                              "Aplicar"
                            )}
                          </Button>            
                        </div>
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="row mb-4 justify-content-end">
                        <div className="col-auto h6 text-left">
                          {getMessage("getting_shop")}:{" "}
                        </div>
                        <div className="col-auto">
                          <select
                            name="deliveryMode"
                            className="form-control"
                            name="deliveryMode"
                            value={this.state.deliveryMode}
                            onChange={(e) => {
                              this.handleChange({
                                target: {
                                  name: "deliveryMode",
                                  value: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="0">{getMessage("not")}</option>
                            <option value="1">{getMessage("yes")}</option>                 
                          </select>
                        </div>
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="row mb-4 justify-content-end">
                        <div className="col-auto h6 text-left">
                          {getMessage("type_cep_address")}:{" "}
                        </div>
                        <div className="col-auto">
                          <InputMask
                            mask="99999-999"
                            id="zip-code-input"
                            className="input-zip-cart"
                            value={zip_code}
                            onChange={(e) =>
                              this.handleChangeZip(e.target.value)
                            }
                          />
                          {loadingFare === true && (
                            <div className="ring-2">
                              <div className="ball-holder">
                                <div className="ball"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="row mb-4 justify-content-end">
                        {zip_code.match(/\d/g) &&
                          zip_code.match(/\d/g).join("").length === 8 &&
                          orders_available === false && (
                            <div className="mt-3 alert alert-warning">
                              {getMessage("unavailable_to_shopping")}
                            </div>
                          )}
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="row mb-4 justify-content-end">
                        {this.verifyCupom()}
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="table-cart-middle row justify-content-end mb-2">
                        <div className="col-auto col-sm-auto col-md-auto">
                          <h3 className="h5">{getMessage("freight_fare")}</h3>
                        </div>
                        <div className="col-8 col-sm-auto text-right">
                          <h3 className="h5">
                            <strong>
                              {currencyFormatter.format(fare_price, {
                                locale: "pt-BR",
                              })}
                            </strong>
                          </h3>
                        </div>
                      </div>
                    )}
                    {products.length > 0 && (
                      <div className="table-cart-middle row justify-content-end mb-2">
                        <div className="col-auto col-sm-auto col-md-auto">
                          <h3>{getMessage("total")}</h3>
                        </div>
                        <div className="col-8 col-sm-auto text-right">
                          <h3>
                            <strong>
                              {currencyFormatter.format(
                                convertCentsToFloat(
                                  this.calcTotalPrice(total_price)
                                ) + fare_price,
                                { locale: "pt-BR" }
                              )}
                            </strong>
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                  {products.length > 0 && (
                    <div className="footer-cart text-right">
                      <button
                        type="button"
                        className="btn btn-default ml-2 mr-2"
                        onClick={this.goToHome}
                      >
                        {getMessage("continue_to_shopping")}
                      </button>
                      {orders_available || deliveryMode === '1' ? (
                        <button
                          type="button"
                          className="btn btn-success ml-2 mr-2"
                          onClick={this.seePayment}
                        >
                          {getMessage("checkout")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn-success ml-2 mr-2"
                        >
                          {getMessage("checkout")}
                        </button>
                      )}
                    </div>
                  )}
                </main>
              </div>
              <Footer />
            </Loading>
          );
      }
      catch(error) {
        return <div className='text-center h1 p-5 m-auto'>{error.message}</div>;
      }
    }
}
export default withAlert()(withRouter(connect(store => ({products: store.products, user: store.user}))(ShoppingCart)));