import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from '~/store/index';
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import Header from '~/components/header';
import indexRoutes from "~/routes/index.jsx";
import { getUserData } from './assets/requests/auth';
import { positions, Provider as ProviderAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Login from '~/views/login/index.jsx';

const options = {
    timeout: 3000,
    position: positions.BOTTOM_CENTER,
    containerStyle: {
      zIndex: 999999
    }
};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            loaded: false,
            user: {}
        }
    }

    /**
     * Carrega os dados do usuário
     */
    userData = async () => {
        const state = Object.assign({}, this.state);
        let response = await getUserData();
        if ( response.status === true ) {
            state.logged = true;
            state.user = response.data;
        }
        state.loaded = true;
        this.setState(state);
    };

    async componentWillMount() {
        await this.userData();
    }

    render() {
        try {
            const { logged, loaded } = this.state;
            return(
                <>
                <ProviderAlert template={AlertTemplate} {...options}>
                    <BrowserRouter>
                        <Provider store={Store}>
                            <Header logged={this.state.logged} user={this.state.user}/>
                            <Switch>
                                {indexRoutes.map((prop, key) => {
                                    if (prop.title) document.title = prop.title;
                                    if (prop.only) {
                                        if (prop.only === 'not-logged' && logged === false) {
                                            return <Route path={prop.path} render={props => <prop.component {...props} {...this.state} />} key={key} />;
                                        }
                                        else if (prop.only === 'all') {
                                            return <Route path={prop.path} render={props => <prop.component {...props} {...this.state} />} key={key} />;
                                        }
                                        else if (prop.only === 'logged' && logged === false && loaded === true) {
                                            return <Redirect from={prop.path} to='/login' key={key} />;
                                        }
                                        else if (prop.only === 'logged' && logged === true) {
                                            return <Route path={prop.path} render={props => <prop.component {...props} {...this.state} />} key={key} />;
                                        }
                                    } else {
                                        return <Route path={prop.path} render={props => <prop.component {...props} {...this.state} />} key={key} />;
                                    }
                                })}
                            </Switch>
                        </Provider>
                    </BrowserRouter>
                </ProviderAlert>
                </>
            );
        }
        catch(error) {
            return <div className='container'><h1 className='text-center'>{error.message}</h1></div>;
        }
    }
}
ReactDOM.render(<App />, document.getElementById('root'));
