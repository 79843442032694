import * as actionTypes from '~/actions/actionTypes';

const initialState = {
    products: [],
    delivery_data: {}
};

const products = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FREIGHT_DATA:
            return {
                ...state,
                delivery_data: action.data
            }
        default:
            return state;
    }
}
export default products;