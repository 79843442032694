import React from 'react';
import { withRouter } from 'react-router-dom';
import { getMessage } from '~/assets/utils';

import './css/footer.css';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return(
            <footer className='pb-4 pt-2 text-white-50 sticky-bottom footer-content'>
                <div className='footer-container'>
                    <div className='row pb-4'>
                        <div className='col-sm-4 col-lg-2 mt-2 footer-link-content ml-auto mr-auto'>
                            <p className='footer-title'>{getMessage('policy')}</p>
                            <a href={process.env.REACT_APP_POLICY_EXCHANGES_URL}>{getMessage('policy_exchanges')}</a>
                            <a href={process.env.REACT_APP_TERMS_URL}>{getMessage('terms_of_use')}</a>
                        </div>
                        <div className='col-sm-4 col-lg-2 mt-2 footer-link-content ml-auto mr-auto'>
                            <p className='footer-title'>{getMessage('faq')}</p>
                            <a href={process.env.REACT_APP_CONTACT_URL}>{getMessage('contact_us')}</a>
                        </div>
                        <div className='col-sm-4 col-lg-3 mt-2 ml-auto mr-auto'>
                            <p className='footer-title'>{getMessage('payment_types')}</p>
                            <img alt='Visa' src={require('./images/logo_visa.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                            <img alt='Mastercard' src={require('./images/logo_mastercard.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                            <img alt='Hipercard' src={require('./images/logo_hipercard.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                            <img alt='Elo' src={require('./images/logo_elo.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                            <img alt='Elo Black' src={require('./images/logo_elo-black.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                            <img alt='American' src={require('./images/logo_american.png')} width='56px' height='36px' className='mb-2' />&nbsp;
                        </div>
                        <div className='col-sm-4 col-lg-3 mt-2 ml-auto mr-auto d-none d-lg-block'>
                            <p className='footer-title'>{getMessage('bay_on')}</p>
                            <a href={process.env.REACT_APP_IOS}>
                                <img alt='Apple Store' src={require('./images/img_appstore.png')} width='180px' />
                            </a>
                            <a href={process.env.REACT_APP_ANDROID}>
                                <img alt='Play Store' src={require('./images/img_playstore.png')} width='180px' />
                            </a>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col'>
                            <p>
                                <span dangerouslySetInnerHTML={{__html: process.env.REACT_APP_FOOTER_DESC}} />
                            </p>
                        </div>
                    </div>
                    <hr className='line-divisor' />
                    <div className='row mt-2 footer-content-copyright'>
                        <div className='col-sm-6 col-lg-6 col-xl-6'><p><span>Copyright - {new Date().getFullYear()} AVA Software Solutions - All rights reserved</span></p></div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withRouter(Footer);