import React from 'react';
import { withRouter } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import Footer from '~/components/footer/index';
import { withAlert } from 'react-alert';

import { getProductData, getSimilarProducts } from '~/assets/requests/firebase';
import { getMessage, system_lang, buttonBeforeLoading, buttonToLoading, convertCentsToFloat } from '~/assets/utils';
import { addProductToFavorites, removeFromFavorites } from '~/assets/requests/product';
import { userFavorites } from '~/assets/requests/user';
import * as currencyFormatter from 'currency-formatter';

import { addProductToCart } from '~/assets/requests/product';

import './css/product.css';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const sliderToShowTotal = () => {
    if (window.screen.width >= 1366) return 4;
    if (window.screen.width >= 992 && window.screen.width <= 1280) return 3;
    if (window.screen.width > 640 && window.screen.width <= 768) return 2;
    if (window.screen.width <= 640) return 2;
    return 3;
}
class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            attributes: [],
            favourites: [],
            product_id: '',
            message_load: 'loading_product',
            loaded: false,
            attributes_selected: [],
            settings_carousel: {
                showThumbs: true,
                showIndicators: false,
                showStatus: false,
                emulateTouch: true,
                useKeyboardArrows: true,
                autoPlay: false,
                infiniteLoop: false
            },
            settings_carousel_similar: {
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: sliderToShowTotal(),
                slidesToScroll: sliderToShowTotal(),
                beforeChange: () => false,
                afterChange: () => false
            },
            similar_products: {},
            hovers: [],
            selectedItem: null
        }
        
        this.loadProductImages = () => {
            try {
                const { product } = this.state;
                if ( Object.keys(product).length === 0 ) return false;
                let product_images = [];
                product['images'].map((image, key) => {
                    product_images.push(
                        <div className='slider-product position-relative' key={key}>
                            <LazyLoadImage
                                alt={product.name}
                                effect="blur"
                                src={image} />
                            <img className='img-fluid' src={image} alt={product.name} />
                        </div>
                    );
                    return true;
                });
                
                return product_images;
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.updateState = () => {
            let url = window.location.pathname.split('/');
            if (url.length < 3 || url[2].length === 0) {
                this.props.history.push('/404');
            }
            this.setState({product_id: url[2]});
        };
        this.getProductData = async () => {
            try {
                const { product_id } = this.state;
                let productData = await getProductData(product_id);
                if (this.props.user.logged === true) {
                    let responseFavorites = await userFavorites(this.props.user.user_id);
                    if ( responseFavorites.status === true ) {
                        let indexProduct = responseFavorites.favorites.indexOf(product_id);
                        if ( indexProduct >= 0 ) productData.product.favorite = true;
                    }
                }
                if ( productData.status === true ) {
                    await this.setState({product: productData.product, attributes: productData.attributes, message_load: 'loading_similar_products'});
                    if (this.state.attributes[0]) this.handleChangeProductAttribute(this.state.attributes[0]._id, this.state.attributes[0].children[0]._id)
                    this.getSimilarProducts();
                } else {
                    this.props.history.push('/404');
                }
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.addToCart = async () => {
            try {
                buttonToLoading('btn-action-product');
                const { product_id, attributes_selected, product } = this.state;
                let attribute_id, attribute_value;
                if ( attributes_selected.length === 0 ) {
                    attribute_id = Object.keys(product.attributes[0].values)[0];
                    attribute_value = product.attributes[0].values[attribute_id];
                } else {
                    attribute_id = attributes_selected[0]._id;
                    attribute_value = attributes_selected[0].value;
                }
                let productData = {
                    product_id,
                    attribute_id,
                    attribute_value
                }
                
                await addProductToCart(productData);
                setTimeout(() => {
                    buttonBeforeLoading('btn-action-product');
                    this.props.history.push('/shopping-cart');
                }, 200)
            }
            catch(error) {
                this.props.alert.error(error.mesage);
                return;
            }
        }
        this.addToFavorites = async () => {
            try {
                if (this.props.user.logged === false) {
                    this.props.history.push('/login');
                }
                const { product } = this.state;
                buttonToLoading('btn-favorites');
                if (product.favorite) {
                    let response = await removeFromFavorites(product._id);
                    if ( response.status === true ) {
                        product.favorite = false;
                    }
                } else {
                    let response = await addProductToFavorites(product._id);
                    if ( response.status === true ) {
                        product.favorite = true;
                    }
                }
                this.setState({product});
                buttonBeforeLoading('btn-favorites');
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.seeProduct = (product_id, e) => {
            try {
                e.preventDefault();
                this.props.history.push('/product/' + product_id);
                this.setState({product_id: product_id, loaded: false, message_load: 'loading_product'});
                setTimeout(()=>this.getProductData(), 500);
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.handleChangeProductAttribute = (attributeId, attributeValue) => {
            try {
                const { attributes_selected } = this.state;
                const pushAttributes = () => {
                    attributes_selected.push({
                        _id: attributeId,
                        value: attributeValue
                    });
                }
                if ( attributes_selected.length > 0 ) {
                    let indexSelected = attributes_selected.findIndex(item=>item._id === attributeId);
                    if ( indexSelected >= 0 ) {
                        attributes_selected[indexSelected].value = attributeValue;
                    } else {
                        pushAttributes();
                    }
                } else {
                    pushAttributes();
                }
                this.setState({attributes_selected});
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.renderAttributes = () => {
            try {
                let attributesData = [];
                const { attributes } = this.state
                if (attributes.length === 0) return false;

                const renderOptions = (options) => {
                    let list_options = [];
                    options.map((option, key) => {
                        list_options.push(
                            <option value={option._id} key={key}>{option.name}</option>
                        );
                        return true;
                    })
                    return list_options
                }

                attributes.map((attribute, key) => {
                    attributesData.push(
                        <div className='attribute-content' key={key}>
                            <label className='attribute-label'>{attribute.name}</label>
                            <select className='attribute-select' onChange={(e) => this.handleChangeProductAttribute(attribute._id, e.target.value)}>{renderOptions(attribute.children)}</select>
                        </div>
                    );
                    return true;
                })
                return attributesData;
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        /**
         * Carrega a imagem do produto
         * @param {*} product 
         * @param {*} product_id 
         */
        this.productImage = (product, product_id) => {
            try {
                if (this.state.hovers.includes(product_id)) {
                    return (product.images[1] || product.images[0]);
                }
                else {
                    return product.images[0];
                }
            }
            catch (error) {
                return false;
            }
        }
        /**
         * Remove o hover do produto
         * @param {*} product_id 
         */
        this.onProductLeve = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    const indexOf = this.state.hovers.findIndex(item => item === product_id);
                    if (indexOf >= 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.splice(indexOf, 1);
                        this.setState({hovers});
                    }
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * Remove o enter de um produto
         * @param {*} product_id 
         */
        this.onProductEnter = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    if (this.state.hovers.findIndex(item => item === product_id) < 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.push(product_id);
                        this.setState({hovers});
                    }
                }
                else {
                    const hovers = Object.assign([], this.state.hovers);
                    hovers.push(product_id);
                    this.setState({hovers});
                }
            }
            catch (error) {
               return false; 
            }
        }
        this.loadSimilarProducts = () => {
            try {
                let listSimilar = [];
                const { similar_products, product_id } = this.state;
                similar_products.filter(item => {
                    if (item._id !== product_id) return item;
                }).map((product, key) => {
                    listSimilar.push(
                        <a className='similar-product cursor-pointer mb-0' href={process.env.REACT_APP_URL + '/product/' + product._id} key={key} onClick={(e) => this.seeProduct(product._id, e)}>
                            <div className='similar-product-img'
                                onMouseOver={() => this.onProductEnter(product._id)}
                                onMouseOut={() => this.onProductLeve(product._id)}>
                                <LazyLoadImage
                                    alt={product.name}
                                    height={152}
                                    width={'auto'}
                                    effect="blur"
                                    src={this.productImage(product, product._id)} />
                            </div>
                            <div className='similar-product-details'>
                                <div className='similar-product-price pl-3 pr-3 pb-1 text-center'>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                                <div className='similar-product-name pl-3 pr-3 pb-2 text-center'>{product.name}</div>
                            </div>
                        </a>
                    );
                    return true;
                });

                return listSimilar;
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
        this.getSimilarProducts = async () => {
            try {
                const { product } = this.state;
                if (product.categories) {
                    let categoryRand = product.categories[Math.floor(Math.random() * product.categories.length)];
                    let response = await getSimilarProducts(categoryRand._id);
                    if ( response.status === true ) {
                        this.setState({similar_products: response.products});
                    }
                }
                this.setState({message_load: 'all_is_ready', loaded: true});
            }
            catch(error) {
                this.props.alert.error(error.message);
                return;
            }
        }
    }

    async componentDidMount() {
        try {
            window.screenTop(0, 0);
        } catch (error) {
            
        }
        await this.updateState();
        this.getProductData();
    }

    render() {
        const { settings_carousel, loaded, product, similar_products, settings_carousel_similar, attributes } = this.state;
        return(
            <>
                <div className='container-page container-fluid show mt-2 pt-4 pb-4 mb-5'>
                    <main>
                        <div className='product-content container mb-4'>
                            <div className='row'>
                                <div className='col-12 col-lg-7'>
                                    {loaded === false
                                    ?
                                    <Skeleton className='wrapper-carousel-product' height={300} />
                                    :
                                    <Carousel
                                        className='wrapper-carousel-product'
                                        {...settings_carousel}>
                                            {this.loadProductImages()}
                                    </Carousel>}
                                </div>
                                <div className='col-12 col-lg-5'>
                                    <div className='wrapper-product-info w-100'>
                                        <h1 className='title-product-info h5 mb-3'>{loaded === false ? <Skeleton width='150px' height='25px' /> : product.name}</h1>
                                        <p className='ml-auto mr-auto description-product-info mb-2'>{loaded === false ? <Skeleton width='150px' height='25px' /> : product.description}</p>
                                        <div className='product-metric-details w-100'>{this.renderAttributes()}</div>
                                        <div className='price-product-info h2'>{currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                                        {attributes.length > 0 && Object.keys(product.attributes).length > 0 &&
                                        <div className='product-actions w-100 d-md-flex d-xl-block flex-wrap'>
                                            <div className='button-actions mb-2 mr-0 mr-md-3 mr-xl-0'>
                                                <button className='btn btn-secondary btn-action-product' onClick={this.addToCart}>{getMessage('add_to_cart')}</button>
                                            </div>
                                            <div className='button-actions'>
                                                <button className='btn btn-action-default btn-favorites' onClick={this.addToFavorites}>{getMessage(product.favorite === false ? 'add_to_favorites' : 'remove_from_favorites')}</button>
                                            </div>
                                        </div>}
                                        {loaded === false ? <Skeleton width='150px' height='25px' /> : <>
                                        {(attributes.length === 0 || Object.keys(product.attributes).length === 0) &&
                                        <div className='product-actions w-100 d-md-flex d-xl-block flex-wrap'><p className='m-auto out-of-stock'>{getMessage('out_of_stock')}</p></div>}</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='product-details container'></div>
                        {similar_products.length > 0 &&
                        <div className='similar-products container'>
                            <div className='similar-product-title pt-4 pb-2'>{getMessage('maybe_you_like_it_products')}</div>
                            <Slider className='mr-md-3 ml-md-3' {...settings_carousel_similar}>{this.loadSimilarProducts()}</Slider>
                        </div>
                        }
                    </main>
                </div>
                <Footer />
            </>
        )
    }
}
export default withRouter(connect(store => ({products: store.products, user: store.user}))(withAlert()(Product)));