let defaultFirebase = require('./index.js');
const firebase = defaultFirebase.default;
/**
 * retorna dados de uma determinada tabela
 * @param {*} table - tabela a ser consultada
 */
export const getFirebaseData = async (table) => {
    try {
        let data_result;
        let consultFirebase = await firebase.database().ref(table).once('value');
        data_result = consultFirebase.val();
        return {
            status: true,
            data: data_result
        };
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Configurações do sistema da loja
 * @return {Object}
 */
export const systemSettings = async () => {
    const fallbackSettings = {
        minimumVersion: {
            android: 1
        },
        packageSettings: {
            deliveryCEP: "89221480",
            packageDiameter: 0,
            packageHeight: 20,
            packageItems: 5,
            packageLength: 20,
            packageWeight: 5,
            packageWidth: 20
        },
        paymentSettings: {
            bankSlip: false,
            creditCard: true,
            debitCard: false,
            delivery: true,
            deliveryMode: 0,
            staticDelivery: 2,
            staticFare: 1500,
            takeOut: true,
            online: true
        }
    }
    try {
        let response = await getFirebaseData('settings');
        if (response.status === true) {
            return {
                status: true,
                data: response.data
            }
        } else {
            return {
                status: false,
                message: response.message,
                data: fallbackSettings
            };
        }
    }
    catch(error) {
        return {
            status: false,
            message: error.message,
            data: fallbackSettings
        }
    }
}