import React from 'react';
import Footer from '~/components/footer';
import Skeleton from 'react-loading-skeleton';

export default class LoadingCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        const state = Object.assign({}, this.state);
        for (let key in this.props) {
            if (state.hasOwnProperty(key)) {
                state[key] = this.props[key];
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loaded !== this.props.loaded) {
            this.setState({loaded: this.props.loaded});
        }
    }

    render() {
        const { loaded } = this.state;
        if (loaded === false) {
            return(
                <div>
                    <div className='row justify-content-md-center row-skeleton'>
                        <Skeleton width='245px' style={{marginRight: 10}} height='274px' />
                        <Skeleton width='245px' style={{marginRight: 10}} height='274px' />
                        <Skeleton width='245px' style={{marginRight: 10}} height='274px' />
                        <Skeleton width='245px' style={{marginRight: 10}} height='274px' />
                    </div>
                </div>
            )
        }
        else {
            return this.props.children;
        }
    }
}