import React from 'react';
import { withRouter } from'react-router-dom';
import { connect } from 'react-redux';

import Header from '~/components/header/index';
import Footer from '~/components/footer/index';
import Loading from '~/components/loading/loading';

import { getMessage, buttonBeforeLoading, buttonToLoading } from '~/assets/utils';
import { recoveryPassword } from '~/assets/requests/auth';

class RecoveryPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            loaded: false,
            password: '',
            repeat_password: '',
            message_error: '',
            message_successfully: '',
            message_error_request: '',
            submited: false,
            verification_code: ''
        }

        this.handleChange = (e) => {
            const state = Object.assign({}, this.state);
            state[e.target.name] = e.target.value;
            state.message_error = '';
            state.message_successfully = '';
            state.message_error_request = '';
            this.setState(state);
        }
        this.submitFogort = async (e) => {
            e.preventDefault();
            if ( this.state.submited === true ) return false;

            if ( this.state.password.length === 0 ) {
                this.setState({message_error: 'password_is_required', submited: false, message_successfully: '', message_error_request: ''});
                return false;
            }
            if ( this.state.repeat_password.length === 0 ) {
                this.setState({message_error: 'repeat_password_is_required', submited: false, message_successfully: '', message_error_request: ''});
                return false;
            }
            if ( this.state.repeat_password !== this.state.password ) {
                this.setState({message_error: 'password_is_not_equal', submited: false, message_successfully: '', message_error_request: ''});
                return false;
            }
            buttonBeforeLoading('btn-loading');
            buttonToLoading('btn-recovery');
            let response = await recoveryPassword(this.state.password, this.state.verification_code);
            if ( response.status === true ) {
                this.setState({message_error: '', submited: false, message_successfully: response.message, message_error_request: ''});
            } else {
                this.setState({message_error_request: response.message, submited: false, message_successfully: '', message_error: ''});
            }
            buttonBeforeLoading('btn-loading');
        }
        this.openLogin = async (e) => {
            e.preventDefault();
            this.props.history.push('/login');
        }
    }

    componentDidMount() {
        
        let url_string = window.location.href
        let url = new URL(url_string);
        let verification_code = url.searchParams.get("verification_code");
        if ( !verification_code ) this.props.history.push('/forgot-password');
        this.setState({message_load: 'all_is_ready', loaded: true, verification_code});
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/recovery.css');
        const { message_load, loaded, password, repeat_password, message_error, message_successfully, message_error_request } = this.state;
        return(
            <Loading message={message_load} loaded={loaded}>
                    {
                        this.props.user.logged === true && (() => window.location.href = process.env.REACT_APP_URL)
                    }
                    <div className={`recovery-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                        <main>
                            <div className='row justify-content-center'>
                                <section className='section-recovery col-12 col-sm-8 col-md-6 active'>
                                    <div className='container-form-area'>
                                        <form className='form-horizontal' onSubmit={this.submitFogort}>
                                            <fieldset>
                                                <legend className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'><div className='title-section'>{getMessage('change_password') + ' - ' + process.env.REACT_APP_NAME}</div></legend>
                                                <div className='form-group'>
                                                    <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                        <input id='recovery-password' className='form-control input-md pl-0' name='password' placeholder=' ' value={password} onChange={this.handleChange} type='password'/>
                                                        <label className='pl-3 pr-3 control-label' htmlFor='recovery-password'>{getMessage('type_new_password')}</label>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                        <input id='recovery-repeat-password' className='form-control input-md pl-0' name='repeat_password' placeholder=' ' value={repeat_password} onChange={this.handleChange} type='password'/>
                                                        <label className='pl-3 pr-3 control-label' htmlFor='recovery-repeat-password'>{getMessage('repeat_new_password')}</label>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    {message_error.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_error)}</div>}
                                                    {message_error_request.length > 0 && <div className='alert alert-danger col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_error_request)}</div>}
                                                    {message_successfully.length > 0 && <div className='alert alert-success col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_successfully)}</div>}
                                                    <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                        <button type='submit' className='btn btn-primary d-block btn-loading btn-recovery'>{getMessage('save_new_password')}</button>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                        <div className='m-auto change-section-recovery text-center'>{getMessage('have_account')} <a href={process.env.REACT_APP_URL + '/login'} onClick={(e) => this.openLogin(e)}>{getMessage('login')}</a></div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                <Footer />
            </Loading>
        )
    }
}
export default withRouter(RecoveryPassword);