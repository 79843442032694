import React from 'react';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Loading from '~/components/loading/loading';
import NotFound from '~/components/not-found/index';

import { connect } from 'react-redux';
import * as currencyFormatter from 'currency-formatter';

import { getFavoriteProducts } from '~/assets/requests/product';
import { system_lang, getMessage, convertCentsToFloat } from '~/assets/utils';

class Favourites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading_products',
            loaded: false,
            products: []
        }

        this.getProducts = async () => {
            let response = await getFavoriteProducts();
            if ( response.status === true ) {
                this.setState({message_load: 'all_is_ready', loaded: true, products: response.products});
            }
        }
        this.seeProduct = (product_id, e) => {
            e.preventDefault();
            this.props.history.push('/product/' + product_id);
        }
        this.userSession = () => {
            this.setState({user: this.props.user});
        }
    }

    componentDidMount() {
        document.querySelector('body').classList.remove('scroll-false');
        this.userSession();
        this.getProducts();
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/favorites.css');
        const { message_load, loaded, products } = this.state;
        return(
            <Loading message={message_load} loaded={loaded}>
                <div className={`favorites-container container-fluid mt-2 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                    <main className='w-100'>
                        <h1 className='title-page h3 w-100 w-md-75 ml-auto mr-auto'>{getMessage('favorites')}</h1>
                        <div className='d-flex flex-wrap w-100 w-md-75 ml-auto mr-auto'>
                        {products.map((product, key) => {
                            return(
                                <a href={process.env.REACT_APP_URL + '/product/' + product._id} className='item-product cursor-pointer' key={key} onClick={(e) => this.seeProduct(product._id, e)}>
                                    <div className='item-product-img'>
                                        <img className='img-fluid' src={product.images[0]} alt={product.name} />
                                    </div>
                                    <div className='item-product-details'>
                                        <div className='item-product-price pl-3 pr-3 pb-1 text-center'>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</div>
                                        <div className='item-product-name pl-3 pr-3 pb-2 text-center'>{product.name}</div>
                                    </div>
                                </a>
                            );
                        })}
                        </div>
                        {products.length === 0 && <NotFound title='none_favorites' icon_url={require('../../components/not-found/img/img_empty_fav.png')} description='none_products_on_favorites'/>}
                    </main>
                </div>
            </Loading>
        )
    }
}
export default connect(store => ({user: store.user}))(Favourites);