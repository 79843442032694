import React from 'react';
import { withRouter } from'react-router-dom';

import Footer from '~/components/footer/index';
import Loading from '~/components/loading/loading';
import { withAlert } from 'react-alert';

import { getMessage, buttonBeforeLoading, buttonToLoading } from '~/assets/utils';
import { userSaveInfo } from '~/assets/requests/user';
import ReactInputMask from 'react-input-mask';
class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            loaded: false,
            submited: false,
            message_error: '',
            message_successfully: '',
            logged: false,
            user: {
                createdAt: 0,
                email: '',
                name: '',
                phone: '',
                status: '',
                surname: '',
                user_id: 0,
                sharcode: ''
            }
        }

        this.submitChanges = async (e) => {
            try {
                e.preventDefault();
                if (this.state.submited === true) return false;
                buttonBeforeLoading('btn-loading');
                buttonToLoading('btn-save');

                const state = Object.assign({}, this.state);
                this.setState({submited: true, message_error: '', message_successfully: '', user: state.user});

                if (state.user.name.length === 0) {
                    state.message_error = 'first_name_is_required';
                    this.setState({message_error: state.message_error, submited: false});
                    buttonBeforeLoading('btn-loading');
                    return false;
                }
                if (state.user.surname.length === 0) {
                    state.message_error = 'last_name_is_required';
                    this.setState({message_error: state.message_error, submited: false});
                    buttonBeforeLoading('btn-loading');
                    return false;
                }

                let response = await userSaveInfo(state.user);
                if ( response.status === true ) {
                    this.setState({message_error: '', message_successfully: 'save_success_changes', submited: false});
                    setTimeout(() => {
                        this.setState({message_user_failed: '', message_user_success: ''});
                    }, 1200);
                } else {
                    this.setState({message_error: response.message, message_successfully: '', submited: false});
                }
                buttonBeforeLoading('btn-loading');
            }
            catch(error) {
                this.props.alert.error(error.message);
                this.setState({message_error: '', message_successfully: '', submited: false});
                buttonBeforeLoading('btn-loading');
                return;
            }
        }
        this.userSession = () => {
            try {
                this.setState({user: this.props.user});
            }
            catch(error) {
                throw new error(error.message);
            }
        }
        this.handleChange  = (e) => {
            const user = Object.assign({}, this.state.user);
            user[e.target.name] = e.target.value;
            this.setState({user});
        }
        this.openShare = () => {
            try {
                const { user } = this.state;
            
                let text = 'Ganhe descontos ao se cadastrar e comprar na Gobelin com o meu código.';
                let link = 'https://loja.gobelin.com.br/login?sharcode=' + user.sharcode;
                navigator.share({
                    title: `Gobelin Código promocional`,
                    text: `${text}\n\n${link}`,
                    
                })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    return false;
                });
            }
            catch(error) {
                return false;
            }
        
        }
    }

    componentDidMount() {
        document.querySelector('body').classList.add('scroll-false');
        this.userSession();
        this.setState({message_load: 'all_is_ready', loaded: true});
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        try {
            require('./css/account.css');
            const { message_error, message_successfully, loaded, message_load, user } = this.state;
            return(
                <Loading message={message_load} loaded={loaded}>
                        <div className={`settings-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                            <main>
                                <div className='row justify-content-center'>
                                    <section className='section-settings col-12 col-sm-8 col-md-6 active'>
                                        <div className='container-form-area'>
                                            <form className='form-horizontal' onSubmit={this.submitChanges}>
                                                <fieldset>
                                                    <legend className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'><div className='title-section'>{getMessage('general_settings')}</div></legend>
                                                    <div className='form-group'>
                                                        <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                            <input id='settings-email' className='form-control input-md pl-0' name='email' placeholder=' ' readOnly={true} value={user.email} onChange={this.handleChange} type='email'/>
                                                            <label className='pl-3 pr-3 control-label' htmlFor='login-email'>{getMessage('email')}</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                            <input id='settings-first-name' className='form-control input-md pl-0' name='name' placeholder=' ' value={user.name} onChange={this.handleChange} type='text'/>
                                                            <label className='pl-3 pr-3 control-label' htmlFor='settings-first-name'>{getMessage('first_name')}</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                            <input id='settings-last-name' className='form-control input-md pl-0' name='surname' placeholder=' ' value={user.surname} onChange={this.handleChange} type='text'/>
                                                            <label className='pl-3 pr-3 control-label' htmlFor='settings-last-name'>{getMessage('last_name')}</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                            <ReactInputMask mask='(**) *-****-****'  id='settings-phone' className='form-control input-md pl-0' name='phone' placeholder=' ' value={user.phone} onChange={this.handleChange} type='text'/>
                                                            <label className='pl-3 pr-3 control-label' htmlFor='settings-phone'>{getMessage('phone')}</label>
                                                        </div>
                                                    </div>
                                                    {user.sharcode &&
                                                    <div className='form-group'>
                                                        <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                            <label className='control-label'>{getMessage('sharcode_link')}</label>
                                                        </div>
                                                        <div className="col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto d-flex flex-wrap">
                                                            <a className='d-none d-lg-block w-100'>
                                                                Compartilhar na redes sociais
                                                            </a>
                                                            <a className='d-none d-lg-block social-slide' href={`https://api.whatsapp.com/send?phone=${user.phone}&text=Ganhe descontos ao se cadastrar e comprar na Gobelin com o meu código. ${process.env.REACT_APP_URL}/login?sharcode=${user.sharcode}`}>
                                                                <div className="whatsapp-hover" />
                                                            </a>
                                                            <a className='d-none d-lg-block social-slide' href={`fb-messenger://share/?link=${process.env.REACT_APP_URL}/login?sharcode=${user.sharcode}`}>
                                                                <div className="facebook-hover" />
                                                            </a>
                                                            <a className='d-none d-lg-block social-slide' href={`http://twitter.com/share?text=Ganhe descontos ao se cadastrar e comprar na Gobelin com o meu código&url=${process.env.REACT_APP_URL}/login?sharcode=${user.sharcode}`}>
                                                                <div className="twitter-hover" />
                                                            </a>
                                                            <a className='d-none d-lg-block social-slide' href={`mailto:?subject=Código Promocional Gobelin&body=Ganhe descontos ao se cadastrar e comprar na Gobelin com o meu código&url=${process.env.REACT_APP_URL}/login?sharcode=${user.sharcode}`}>
                                                                <div className="email-hover" />
                                                            </a>
                                                            <a className='d-block d-lg-none' style={{color: 'blue'}} onClick={this.openShare}>
                                                                Compartilhar na redes sociais
                                                            </a>
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className='form-group'>
                                                        {message_error.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_error)}</div>}
                                                        {message_successfully.length > 0 && <div className='alert alert-success col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_successfully)}</div>}
                                                        <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                            <button type='submit' className='btn btn-primary d-block btn-loading btn-save'>{getMessage('save_changes')}</button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </section>
                                </div>
                            </main>
                        </div>
                    <Footer />
                </Loading>
            );
        }
        catch(error) {
            return <div><h1>{error.message}</h1></div>
        }
    }
}
export default withAlert()(withRouter(AccountPage));