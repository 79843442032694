import React from 'react';
import { withRouter } from'react-router-dom';
import { connect } from 'react-redux';

import Header from '~/components/header/index';
import Footer from '~/components/footer/index';
import Loading from '~/components/loading/loading';

import { getMessage, buttonBeforeLoading, buttonToLoading } from '~/assets/utils';
import { forgotPassword } from '~/assets/requests/auth';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            loaded: false,
            email: '',
            message_error: '',
            message_successfully: '',
            submited: false
        }

        this.handleChange = (e) => {
            const state = Object.assign({}, this.state);
            state[e.target.name] = e.target.value;
            state.message_error = '';
            state.message_successfully = '';
            this.setState(state);
        }
        this.submitFogort = async (e) => {
            e.preventDefault();
            if ( this.state.submited === true ) return false;

            if ( this.state.email.length === 0 ) {
                this.setState({message_error: 'email_is_required', submited: false, message_successfully: ''});
                return false;
            }
            buttonBeforeLoading('btn-loading');
            buttonToLoading('btn-forgot');
            let response = await forgotPassword(this.state.email);
            if ( response.status === true ) {
                this.setState({message_error: '', submited: false, message_successfully: response.message});
            } else {
                this.setState({message_error: response.message, submited: false, message_successfully: ''});
            }
            buttonBeforeLoading('btn-loading');
        }
        this.openLogin = async (e) => {
            e.preventDefault();
            this.props.history.push('/login');
        }
    }

    componentDidMount() {
        
        this.setState({message_load: 'all_is_ready', loaded: true});
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/forgot.css');
        const { message_load, loaded, email, message_error, message_successfully } = this.state;
        return(
            <Loading message={message_load} loaded={loaded}>
                    {
                        this.props.user.logged === true && (() => window.location.href = process.env.REACT_APP_URL)
                    }
                    <div className={`forgot-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                        <main>
                            <div className='row justify-content-center'>
                                <section className='section-forgot col-12 col-sm-8 col-md-6 active'>
                                    <div className='container-form-area'>
                                        <form className='form-horizontal' onSubmit={this.submitFogort}>
                                            <fieldset>
                                                <legend className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'><div className='title-section'>{getMessage('forgot_password') + ' - ' + process.env.REACT_APP_NAME}</div></legend>
                                                <div className='form-group'>
                                                    <div className='col-md-12 col-lg-9 col-xl-7 label-float ml-auto mr-auto'>
                                                        <input id='forgot-email' name='email' placeholder=' ' value={email} onChange={this.handleChange} type='text' className='form-control input-md pl-0'/>
                                                        <label className='pl-3 pr-3 control-label' htmlFor='forgot-email'>{getMessage('account_email')}</label>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    {message_error.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_error)}</div>}
                                                    {message_successfully.length > 0 && <div className='alert alert-success col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto' role='alert'>{getMessage(message_successfully)}</div>}
                                                    <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                        <button type='submit' className='btn btn-primary d-block btn-loading btn-forgot'>{getMessage('recovery')}</button>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='col-md-12 col-lg-9 col-xl-7 ml-auto mr-auto'>
                                                        <div className='m-auto change-section-forgot text-center'>{getMessage('have_account')} <a href={process.env.REACT_APP_URL + '/login'} onClick={(e) => this.openLogin(e)}>{getMessage('login')}</a></div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                <Footer />
            </Loading>
        )
    }
}
export default withRouter(ForgotPassword);