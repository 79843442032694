import React from 'react';
import Footer from '~/components/footer';
import Loading from '~/components/loading/loading';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'react-credit-cards';
import * as currencyFormatter from 'currency-formatter';
import {
    getMessage,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    system_lang,
    buttonBeforeLoading,
    buttonToLoading,
    convertCentsToFloat,
    getParamUrl
} from '~/assets/utils';

import 'react-credit-cards/es/styles-compiled.css';
import { createOrder, processPaymentOrder } from '~/assets/requests/product';

const { getFirebaseData } = require('~/firebase/functions');

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            logged: false,
            user: {
                createdAt: 0,
                email: '',
                name: '',
                phone: '',
                status: '',
                surname: '',
                user_id: 0,
                sharcode: ''
            },
            delivery_data: {},
            number: '',
            name: '',
            expiry: '',
            cvc: '',
            issuer: '',
            focused: '',
            formData: null,
            payment_success: '',
            payment_error: ''
        }
        this.userSession = () => {
            this.setState({user: this.props.user});
        }
        this.handleCallback = ({ issuer }, isValid) => {
            if (isValid) {
              this.setState({ issuer });
            }
        };
        
        this.handleInputFocus = ({ target }) => {
            this.setState({
              focused: target.name,
            });
        };
        
        this.handleInputChange = ({ target }) => {
           try {
            if (target.name === 'number') {
                target.value = formatCreditCardNumber(target.value);
              } else if (target.name === 'expiry') {
                target.value = formatExpirationDate(target.value);
              } else if (target.name === 'cvc') {
                target.value = formatCVC(target.value);
              }
          
              this.setState({ [target.name]: target.value });
           }
           catch(error) {
               this.props.alert.error(error.message);
               return;
           }
        };

        this.handleChange = (e) => {
            const delivery_data = Object.assign({}, this.state.delivery_data);
            delivery_data[e.target.name] = e.target.value;
            this.setState({delivery_data});
        }
        
        this.handleSubmit = async (e) => {
            e.preventDefault();
            buttonBeforeLoading('btn-payment');
            buttonToLoading('btn-payment');
            this.setState({payment_success: '', payment_error: ''});
            const { delivery_data } = this.state;
            if (this.state.expiry.length < 7) {
                this.setState({payment_error: 'invalid_expiry_card'});
                buttonBeforeLoading('btn-payment');
                return false;
            }
            const order = {
                state: delivery_data.state,
                city: delivery_data.city,
                district: delivery_data.district,
                street: delivery_data.street,
                number: delivery_data.number,
                complement: delivery_data.complement || '',
                fare_price: parseInt(delivery_data.deliveryMode) === 1 ? 0 : delivery_data.farePrice,
                total_price: delivery_data.total_price,
                zip_code: delivery_data.zip_code,
                fare_delivery: delivery_data.fareDelivery,
                deliveryMode: delivery_data.deliveryMode
            }

            for (let key in order) {
                if(delivery_data.deliveryMode == 1 && (key == 'zip_code' || key == 'city' || key == 'state' || key == 'district' || key == 'street' || key == 'number')) {
                    order[key] = key == 'number' ? 1 : ' '
                    continue
                }
                if (typeof order[key] === 'string' && order[key].length === 0 && key !== 'complement') {
                    this.setState({payment_error: 'required_fields'});
                    return false;
                }
            }

            if (delivery_data.national_identification.length === 0 ) {
                this.setState({payment_error: 'required_fields'});
                return false;
            }
            if (this.state.cvc.length === 0 || this.state.expiry.length === 0 || this.state.name === 0 ) {
                this.setState({payment_error: 'required_fields'});
                return false;
            }

            let response = undefined;
            if (!getParamUrl('history')) {
                response = await createOrder(order);
            } else {
                response = {
                    status: true,
                    order_id: getParamUrl('history')
                }
            }
            if ((convertCentsToFloat(delivery_data.total_price) + delivery_data.fare_price) <= 100 && delivery_data.installments > 1) {
                delivery_data.installments = 1;
            }
            if ((convertCentsToFloat(delivery_data.total_price) + delivery_data.fare_price) <= 149.99 && delivery_data.installments > 2) {
                delivery_data.installments = 2;
            }
            if ( response.status === true ) {
                let payment_data = {
                    "id": response.order_id,
                    "customerName": this.state.user.name,
                    "customerEmail": this.state.user.email,
                    "customerIdentity": delivery_data.national_identification,
                    "customerIdentityType": "CPF",
                    "orderPrice": delivery_data.total_price + (delivery_data.farePrice ? delivery_data.farePrice : 0),
                    "orderInstallments": delivery_data.installments,
                    "paymentCard": this.state.number,
                    "paymentCardSecurity": this.state.cvc,
                    "paymentCardHolder": this.state.name,
                    "paymentValidity": this.state.expiry,
                    "paymentCardFlag": "VISA",
                    "paymentCardSave": false
                }
                let payment = await processPaymentOrder(payment_data);
                if (payment.status === true) {
                    this.setState({payment_success: 'payment_successed'});
                    setTimeout(() => {
                        this.props.history.push('/shopping-history');
                    }, 12000);
                } else {
                    this.setState({payment_error: payment.message || 'payment_error'});
                }
            } else {
                this.setState({payment_error: response.message});
            }
            buttonBeforeLoading('btn-payment');
        };
    }

    componentDidMount() {
        //if ( Object.keys(this.props.products.delivery_data).length === 0 ) window.location.href = process.env.REACT_APP_URL + '/shopping-cart';
        
        this.userSession();
        if (!this.props.products.delivery_data.installments) this.props.products.delivery_data.installments = 1;
        this.setState({message_load: 'all_is_ready', loaded: true, delivery_data: this.props.products.delivery_data});




        const calcPrice = async () => {
            let totalVal = 0;
            const dd = this.props.products.delivery_data;
    
    
            let products = [];
            let localProducts = JSON.parse(localStorage.getItem('cart')) || [];
            if ( !Array.isArray(localProducts) || localProducts.length === 0 ) {
                return {
                    status: false
                }
            }
            let loadPromisses = localProducts.map(async product => {
                let responseProducts = await getFirebaseData('products/' + product.product_id);
                if ( responseProducts.status === true ) {
                    responseProducts.data.key = product.product_id;
                    let attributes = responseProducts.data.attributes;
                    let attribute_key = undefined;
    
                    for (let key in attributes) {
                        for ( let subKey in attributes[key] ) {
                            if ( subKey === product.attribute_id && attributes[key][subKey] === product.attribute_value ) {
                                attribute_key = key;
                            }
                        }
                    }
    
                    if (!attribute_key) {
                        return {
                            status: false,
                            message: 'empty_products'
                        }
                    }
                    let newAttribute = {};
                    newAttribute[attribute_key] = {
                        ...attributes[attribute_key]
                    }
    
                    products.push({
                        attributes: newAttribute,
                        product: responseProducts.data,
                        quantity: product.quantity
                    });
                }
                return true;
            });
    
            await Promise.all(loadPromisses);
    
            for(const key in products) {
                const qtd = products[key].quantity;
                const { price } = products[key].product;
                if(qtd > 0 && price > 0) {
                    totalVal += qtd * price;
                }
            }

            const delivery_data = Object.assign({}, this.state.delivery_data);
            delivery_data["total_price"] = totalVal;
            this.setState({delivery_data});
            
            if(parseInt(dd.deliveryMode) !== 1) {
                //totalVal + dd.farePrice;
            }
        }


        calcPrice();
    }
    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/payment.css');
        const { message_load, loaded, name, number, expiry, cvc, focused, delivery_data, payment_success, payment_error } = this.state;

        return(
            <Loading message={message_load} loaded={loaded}>
                <div className={`payment-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                    <div className='row'>
                        <div className='App-payment col-12 col-lg-5 m-auto'>
                            <div className='card-layout mb-3'>
                                <Card
                                    number={number}
                                    name={name}
                                    expiry={expiry}
                                    cvc={cvc}
                                    focused={focused}
                                    callback={this.handleCallback}
                                    placeholders={{
                                        name: getMessage('card_user_name')
                                    }}
                                />
                            </div>
                            <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                                <div className='form-group d-inline-flex flex-nowrap align-items-center'>
                                    <label className='mr-2'>{getMessage('installments')}</label>
                                    <select name='installments' className='form-control col-12' onChange={(e) => this.handleChange({
                                        target: {
                                            name: e.target.name,
                                            value: parseInt(e.target.value)
                                        }
                                    })}>
                                        <option value="1">1x</option>
                                        {(convertCentsToFloat(delivery_data.total_price) + delivery_data.fare_price) >= 59.99 && <option value="2">2x</option>}
                                        {(convertCentsToFloat(delivery_data.total_price) + delivery_data.fare_price) >= 89.99 && <option value="3">3x</option>}
                                    </select>
                                </div>
                                <div className='form-group'>
                                    <input
                                        type='number'
                                        name='national_identification'
                                        className='form-control'
                                        placeholder={getMessage('card_national_identification')}
                                        required
                                        value={delivery_data.national_identification}
                                        onChange={this.handleChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                                <div className='form-group'>
                                    <input
                                        type='text'
                                        name='number'
                                        className='form-control'
                                        placeholder={getMessage('card_number')}
                                        pattern='[\d| ]{16,22}'
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                                <div className='form-group'>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder={getMessage('card_user_name')}
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-6'>
                                        <input
                                        type='tel'
                                        name='expiry'
                                        className='form-control'
                                        placeholder={getMessage('card_expiry') + ' (MM/AAAA)'}
                                        pattern='\d\d/\d\d\d\d'
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                        type='tel'
                                        name='cvc'
                                        className='form-control'
                                        placeholder={getMessage('card_cvc')}
                                        pattern='\d{3,4}'
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        />
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <legend className='col-12 mb-3'>{getMessage('invoice_address')}</legend>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='zip_code' name='zip_code' placeholder=' ' defaultValue={delivery_data.zip_code} type='text' className='form-control input-md pl-0' readOnly required/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='zip_code'>{getMessage('zip_code')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='city' name='city' placeholder=' ' defaultValue={delivery_data.city} type='text' className='form-control input-md pl-0' readOnly required/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='city'>{getMessage('city')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='district' name='district' placeholder=' ' defaultValue={delivery_data.district} type='text' className='form-control input-md pl-0' readOnly required/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='district'>{getMessage('district')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='state' name='state' placeholder=' ' defaultValue={delivery_data.street} type='text' className='form-control input-md pl-0' readOnly required/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='state'>{getMessage('street')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='number' name='number' placeholder=' ' defaultValue={delivery_data.number} onChange={this.handleChange} type='text' className='form-control input-md pl-0' required={delivery_data.deliveryMode !== 1}/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='number'>{getMessage('number')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group col-12 col-lg-6'>
                                        <div className='col-12 label-float mr-auto ml-auto'>
                                            <input id='complement' name='complement' placeholder=' ' value={delivery_data.complement} onChange={this.handleChange} type='text' className='form-control input-md pl-0'/>
                                            <label className='pl-3 pr-3 control-label' htmlFor='complement'>{getMessage('complement')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='total-payment'>
                                    <h3>
                                        <strong className='mr-2'>{getMessage('total')}:</strong>
                                        <strong>{currencyFormatter.format((convertCentsToFloat(delivery_data.total_price) + convertCentsToFloat(delivery_data.farePrice)), { locale: system_lang })}</strong>
                                    </h3>
                                </div>
                                <div className='form-actions'>
                                    {payment_error.length > 0 && <div className='alert alert-danger col-12' role='alert'>{getMessage(payment_error)}</div>}
                                    {payment_success.length > 0 && <div className='alert alert-success col-12' role='alert'>{getMessage(payment_success)}</div>}
                                    <button className='btn btn-primary btn-payment'>{getMessage('do_payment')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </Loading>
        )
    }
}

export default withAlert()(withRouter(connect(store => ({
    user: store.user,
    products: store.products
}))(Payment)));