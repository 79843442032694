import { getUserData } from '~/assets/requests/auth';
import * as actionTypes from '~/actions/actionTypes';

const initialState = {
    logged: false,
    data: {}
};
const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGGED:
            return state.logged
        case actionTypes.GET_USER_DATA:
            return state.data
        default:
            return state;
    }
}
export default user;