import React from 'react';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Loading from '~/components/loading/loading';
import { connect } from 'react-redux';

import { getMessage } from '~/assets/utils';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            loaded: false,
            page: 'terms'
        }
        this.renderPageTerms = this.renderPageTerms.bind(this);
        this.renderPagePolicy = this.renderPagePolicy.bind(this);
    }

    componentDidMount() {
        
        let url = window.location.href;
        let page = 'terms';
        if (url.indexOf('terms/terms') >= 0) {
            page = 'terms';
        } else if (url.indexOf('terms/policy') >= 0) {
            page = 'policy';
        }
        this.setState({message_load: 'all_is_ready', loaded: true, page});
    }

    renderPageTerms() {
        return <p>{getMessage('terms_of_use')}</p>
    }
    renderPagePolicy() {
        return <p>{getMessage('privacy_policy')}</p>
    }

    render() {
        require('./css/terms.css');
        const { message_load, loaded, page } = this.state;
        return(
            <Loading message={message_load} loaded={loaded}>
                    <div className={`terms-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                        {page === 'terms' && this.renderPageTerms()}
                        {page === 'policy' && this.renderPagePolicy()}
                    </div>
                <Footer />
            </Loading>
        )
    }
}
export default connect(store => ({user: store.user}))(Terms);