const Payment = require('payment');
/**
 * Procura um arquivo, se não encontrar retorna o fallback que por padrão é null
 * @param {*} path 
 * @param {*} pathFallback 
 */
const tryRequire = (path, pathFallback) => {
    try {
        return require(`${path}`);
    } catch (err) {
        if ( pathFallback ) {
            return require(`${pathFallback}`);
        } else {
            return null;
        }
    }
}
const system_lang = 'pt-BR';
const { messages } = tryRequire('./languages/' + system_lang, './languages/pt-BR');
/**
 * Retorna um texto utilizado como mensagem de algum conteúdo
 * Usado como tradução
 * @param {*} message_key 
 */
const getMessage = (message_key) => {
    if (message_key && messages[message_key]) return messages[message_key];
}
const dropdownCustomMenu = () => {
    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
            }
        }
        }
    }
}
/**
 * Firebase messages
 * @param {*} message 
 */
const firebaseMessage = (message) => {
    if(message === 'The email address is badly formatted.'){
        return message = 'fill_fields_correctly'
    }
    if(message === 'The password is invalid or the user does not have a password.'){
        return message = 'incorrectly_password_or_doesnt_exist'
    }
    if(message === 'There is no user record corresponding to this identifier. The user may have been deleted.'){
        return message = 'email_have_not_account'
    }
    if(message === 'Too many unsuccessful login attempts. Please include reCaptcha verification or try again later'){
        return message = 'exceded_requests'
    }
    if(message === 'We have blocked all requests from this device due to unusual activity. Try again later.'){
        return message = 'suspcious_activity'
    }
}
const openDropdownCustom = (id) => {
    document.getElementById(id).classList.toggle("show");
}
/**
 * Retorna os botões de uma mesma classe aos seus estados padrões antes do loading
 * @param {*} button_class 
 */
const buttonBeforeLoading = (button_class) => {
    document.querySelectorAll('.' + button_class).forEach(button => {
        button.classList.remove('loading');
        button.disabled = false;
    });
}
/**
 * define um estado loading para um botão
 * @param {*} button_class 
 */
const buttonToLoading = (button_class) => {
    let btn = document.querySelector('.' + button_class);
    btn.classList.add('loading');
    btn.disabled = true;
}
/**
 * Converte centavos para valor float
 * @param {*} currency 
 */
const convertCentsToFloat = (currency) => {
  if (!currency) return 0;
  currency = currency.toLocaleString();
  currency = currency.match(/\d/g) && currency.match(/\d/g).join('');
  currency = parseInt(currency);
  if ( currency === 0 ) {
      return currency;
  }
  let value = currency
  value = value.toString().replace(/^\D+/g, '');
  value = value.replace(',', '');
  value = parseInt(value).toString();
  
  if (value.length === 0 || value === 0) {
      value = '0,00';
  } else if (value.length === 1) {
      value = '0,0' + value
  } else if (value.length === 2) {
      value = '0,' + value
  } else {
      let position = value.length - 2;
      value = value.slice(0, position) + ',' + value.slice(position);
  }

  value = value.replace(',', '.');
  value = parseFloat(value);
  
  return value
}
/**
 * Retorna apenas números
 * @param {*} value 
 */
const clearNumber = (value = '') => {
    return value.replace(/\D+/g, '');
}
  /**
   * Retorna um número de cartão de crédito formatado
   * @param {*} value 
   */
const formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }
  
    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
  
    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`;
        break;
      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }
  
    return nextValue.trim();
}
/**
 * Retorna o código CVC formatado
 * @param {*} value 
 * @param {*} prevValue 
 * @param {*} allValues 
 */
const formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;
  
    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === 'amex' ? 4 : 3;
    }
  
    return clearValue.slice(0, maxLength);
}
/**
 * Retorna a data de expiração do cartão de crédito formatada
 * @param {*} value 
 */
const formatExpirationDate = (value)  => {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
    }
  
    return clearValue;
}
const getParamUrl = (parameterName) => {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
module.exports = {
    getMessage,
    dropdownCustomMenu,
    openDropdownCustom,
    firebaseMessage,
    system_lang,
    buttonBeforeLoading,
    buttonToLoading,
    convertCentsToFloat,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    getParamUrl
}