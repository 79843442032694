const { getFirebaseData } = require('~/firebase/functions');
const { userFavourites } = require('~/assets/requests/user.js');

/**
 * Retorna os grupos de produtos
 */
export const getGroups = async () => {
    try {
        const response = {
          groups: {},
          products: {},
          ads: {}
        }

        const groups = await getFirebaseData('groups');
        const products = await getFirebaseData('products');
        const ads = await getFirebaseData('ads');

        if ( groups.status === true ) {
          response.groups = Object.assign({}, groups.data);
        }
        if ( products.status === true ) {
          response.products = Object.assign({}, products.data);
        }
        if ( ads.status === true ) {
          response.ads = Object.assign({}, ads.data);
        }

        for (let product in response.products) {
          if (response.products[product].status === 0) {
            delete response.products[product];
          } else if (!response.products[product].images) {
            response.products[product].images = [];
            response.products[product].images.push(process.env.REACT_APP_URL + '/images/no-product-image.png');
          }
        }

        //todo: converte para array
        if ( response.groups ) {
          let groups_keys = [];
          Object.keys(response.groups).forEach(group_key => {
            groups_keys.push(group_key);
          });
          response.groups = Object.entries(response.groups).map((e) => ( { [e[0]]: e[1] } ));
          //todo: joga os valores que antes eram objetos, dentro do array criado anteriormente
          response.groups.forEach((element, key) => {
            let newObjects = {};
            for (let item in response.groups[key]) {
                newObjects = response.groups[key][item];
            }
            newObjects._id = groups_keys[key];
            response.groups[key] = newObjects;
          });
        }
        if ( response.ads ) {
          response.ads = Object.entries(response.ads).map((e) => ({ [e[0]]: e[1] } ));
          response.ads.forEach((element, key) => {
            let newObjects = {};
            for (let item in response.ads[key]) {
                newObjects = response.ads[key][item];
            }
            response.ads[key] = newObjects;
          });

          response.ads.filter(item => item.status === 1);
        }

        return {
            status: true,
            groups: response.groups || [],
            products: response.products || {},
            ads: response.ads || []
          }
          
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Retorna as categorias cadastradas
 */
export const getCategories = async () => {
  try {
      let response = {
        categories: {}
      }
      let categories = await getFirebaseData('categories');
      if ( categories.status === true ) response.categories = Object.assign({}, categories.data);
      if ( response.categories ) {
        //todo: converte para array
        response.categories = Object.entries(response.categories).map((e) => ( { [e[0]]: e[1] } ));
        //todo: joga os valores que antes eram objetos, dentro do array criado anteriormente
        response.categories.forEach((element, key) => {
          let newObjects = {};
          for (let item in response.categories[key]) {
              newObjects = response.categories[key][item];
          }
          newObjects._id = Object.keys(element)[0];
          response.categories[key] = newObjects;
        });
      }
      return {
          status: true,
          categories: response.categories || []
        }
        
  }
  catch(error) {
      return {
          status: false,
          message: error
      }
  }
}
/**
 * Retorna os atributos cadastrados
 */
export const getAttributes = async () => {
  try {
    let response = {
      attributes: {}
    }

    let attributes = await getFirebaseData('attributes');
    /**
     * Monta um array com o objeto atributo fora do objeto produto
     */
    if (attributes.status === true) {
      attributes = Object.assign({}, attributes.data);
      let attributes_keys = [];
      Object.keys(attributes).forEach(attributeKey=> {
        attributes_keys.push({
          _id: attributeKey
        });
        let indexOf = (attributes_keys.length - 1);
        for (let item in attributes[attributeKey]) {
          attributes_keys[indexOf][item] = attributes[attributeKey][item]
        }

        //todo: trata os filhos do atributo
        let newChildren = [];
        let childrens = attributes_keys[indexOf].children;
        Object.keys(childrens).forEach(childrenKey => {
          newChildren.push({
            _id: childrenKey
          });
          for (let item in childrens[childrenKey]) {
            newChildren[newChildren.length-1][item] = childrens[childrenKey][item];
          }
        });

        //todo: os filhos passam a serem de acordo com a tratativa feita anteriormente
        attributes_keys[indexOf].children = newChildren;
      });
      response.attributes = attributes_keys;
      return {
        status: true,
        attributes: response.attributes || []
      }
    } else {
      return {
        status: false,
        message: 'request_returned_empty'
      }
    }
  }
  catch(error) {
    return {
      status: false,
      message: error
    }
  }
}
/**
 * Retorna os produtos de uma categoria
 * @param {*} category_id 
 */
export const getProductsByCategory = async (category_id) => {
  if (!category_id) return { status: false, message: 'invalid_category' }
  try {
    let response = await getFirebaseData('categories/' + category_id);
    let categoryData = {};
    let products = [];
    let attributes = [];
    let ads = {}
    if ( response.status === true ) {
      categoryData = Object.assign({}, response.data);
      response.data.products.forEach( async (product_id) => {
        let productResponse = await getProductData(product_id);
        if ( productResponse.status === true ) {
          products.push(productResponse.product);
        }
      });
      let responseAttributes = await getAttributes();
      if ( responseAttributes.status === true ) {
        attributes = responseAttributes.attributes;
      }
    }
    return {
        status: true,
        products: products || [],
        ads: ads || {},
        attributes: attributes || [],
        category: categoryData || {}
    }
        
  }
  catch(error) {
    return {
        status: false,
        message: error
    }
  }
}
export const getAttributeById = async (attribute_id) => {
  try {
    let attributes = await getFirebaseData('attributes/' + attribute_id);
    if ( attributes.status === true ) {
      return {
        status: true,
        data: attributes.data
      }
    } else {
      return {
        status: false,
        message: 'not_to_return',
        error: attributes
      }
    }
  }
  catch(error) {
    return {
      status: true,
      message: error
    }
  }
}
/**
 * Retorna os dados de um produto específico
 * @param {*} product_id 
 */
export const getProductData = async (product_id) => {
  if (!product_id) {
    return {
      status: false,
      message: 'invalid_product_id'
    }
  }
  try {
    let response = {
      product: {},
      attributes: {}
    }
    let product_data = await getFirebaseData('products/' + product_id);
    let attributes_data = await getAttributes();
    let categories = await getCategories();
    if (product_data.status === true && attributes_data.status === true && categories.status === true) {
      /**
       * Monta um array com o objeto atributo fora do objeto produto
       */
      product_data._id = product_id;
      response.product = product_data.data;
      let product = response.product;
      if (product.attributes) {
        /**
         * Monta as chaves de atributos dentro do produto
         */
        let product_attribute_keys = [];
        Object.keys(product.attributes).forEach(key => {
          product_attribute_keys.push({
            _id: key,
            values: product.attributes[key]
          });
        });
        response.product.attributes = product_attribute_keys;
        
        let attributes = Object.assign({}, attributes_data.attributes);
        let attributes_keys = [];
        Object.keys(attributes).forEach(attributeKey=> {
          attributes_keys.push({
            _id: attributeKey
          });
          let indexOf = (attributes_keys.length - 1);
          for (let item in attributes[attributeKey]) {
            attributes_keys[indexOf][item] = attributes[attributeKey][item]
          }

          //todo: trata os filhos do atributo
          let newChildren = [];
          let childrens = attributes_keys[indexOf].children;
          Object.keys(childrens).forEach(childrenKey => {
            newChildren.push({
              _id: childrenKey
            });
            for (let item in childrens[childrenKey]) {
              newChildren[newChildren.length-1][item] = childrens[childrenKey][item];
            }
          });

          //todo: os filhos passam a serem de acordo com a tratativa feita anteriormente
          attributes_keys[indexOf].children = newChildren;
        });
        response.attributes = attributes_keys;
      }

      if ( !Array.isArray(response.attributes) ) response.attributes = Object.assign([]);

      if ( !response.product.images ) {
        response.product.images = [process.env.REACT_APP_URL + "/images/no-product-image.png"];
      }

      response.product._id = product_id;
      response.product.favorite = false;

      /**
       * Retorna apenas os atributes que tem no produto
       */
      let newAttributes = [];
      response.product.attributes.map(item => {
        if (item.values.quantity > 0) {
          Object.keys(item.values).forEach(_id => {
            response.attributes = response.attributes.filter(item=> {
              if (item._id === _id) {
                if (newAttributes.length === 0) {
                  newAttributes.push(item);
                } else {
                  let indexOf = newAttributes.findIndex(item=>item._id === _id);
                  if (!indexOf) newAttributes.push(item);
                }
              }
            });
          });
        }
      });

      response.attributes = newAttributes;
      let novosAtributos = [];
      response.attributes.forEach(atributo => {
        let novosFilhos = [];
        atributo.children.forEach(filho => {
          response.product.attributes.forEach(produtoAtributo => {
            if (produtoAtributo.values.quantity && produtoAtributo.values.quantity > 0) {
              Object.values(produtoAtributo.values).forEach(valor => {
                if (valor === filho._id) novosFilhos.push(filho);
              });
            }
          });
        });
        novosFilhos.sort(function(a, b) {
          return a.order - b.order;
        });
        atributo.children = novosFilhos;
        novosAtributos.push(atributo);
      });

      response.attributes = novosAtributos;
      response.product.categories = [];
      categories.categories.map(item => {
        let hasCurrentProduct = item.products && item.products.includes(response.product._id);
        if (hasCurrentProduct) {
          response.product.categories.push(item);
          return true;
        } else {
          return false;
        }
      });
      return {
        status: true,
        product: response.product,
        attributes: response.attributes || []
      }
    } else {
      return {
        status: false,
        error: 'not_to_return'
      }
    }
        
  }
  catch(error) {
    return {
        status: false,
        message: error
    }
  }
}
/**
 * Retorna os produtos relacionados à um produto específico
 * @param {*} product_id 
 */
export const getSimilarProducts = async (category_id) => {
  if (!category_id) return { status: false, message: 'empty_categories' };
  try {
      let response = {
        products : []
      }
      let categories = await getFirebaseData('categories/' + category_id);
      if ( categories.status === true ) {
        await Promise.all(Object.values(categories.data.products).map(async key => {
          let product_data = await getProductData(key);
          if ( product_data.status === true ) {
            product_data.product.available = true;
            if (product_data.attributes.length === 0) product_data.product.available = false;
            response.products.push(product_data.product)
          }
        }));
        return {
            status: true,
            products: response.products.sort(item => {
              if (item.available === true) {
                return -1
              } else {
                return 1;
              }
            }),
        }
      } else {
        return {
          status: false,
          message: 'not_to_return',
          error: categories
        }
      }
        
  }
  catch(error) {
      return {
          status: false,
          message: error
      }
  }
}