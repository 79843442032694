/**
 *  firebase/index.js
 *  Ecoville
 *
 *  Last update by Bruno Bandeira on 17/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBSSZRg1rKXSQ4fAyLBYOh3DcKpQYOPMQ4",
    authDomain: "gobelin-favshop.firebaseapp.com",
    databaseURL: "https://gobelin-favshop.firebaseio.com",
    projectId: "gobelin-favshop",
    storageBucket: "gobelin-favshop.appspot.com",
    messagingSenderId: "1022258175266",
    appId: "1:1022258175266:web:511f6ac4374dec3f2ad97b",
    measurementId: "G-YP5MDCS2GV"
  };

firebase.initializeApp(firebaseConfig);
export default firebase;