const { getFirebaseData } = require('~/firebase/functions');
const { getProductData, getAttributes } = require('./firebase');
/**
 * Retorna os produtos de um grupo
 * @param {*} group_id 
 */
export const getProductsByGroup = async (group_id, limit = 11) => {
    if (!group_id) return { status: false, message: 'invalid_group' }
    try {
        let response = await getFirebaseData('groups/' + group_id);
        let groupData = {};
        let products = [];
        let attributes = [];
        let ads = {}
        if ( response.status === true ) {
            groupData = Object.assign({}, response.data);
            response.data.products.slice(0, limit).forEach( async (product_id) => {
                let productResponse = await getProductData(product_id);
                if ( productResponse.status === true ) {
                products.push(productResponse.product);
                }
            });
            let responseAttributes = await getAttributes();
            if ( responseAttributes.status === true ) {
                attributes = responseAttributes.attributes;
            }
        }
        return {
            status: true,
            products: products || [],
            ads: ads || {},
            attributes: attributes || [],
            group: groupData || {}
        }
            
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Retorna os produtos de um grupo
 * @param {*} group_id 
 */
export const getProductsByGroupLast = async (group_id, lastItem) => {
    if (!group_id || !lastItem) return { status: false, message: 'invalid_group' }
    try {
        let response = await getFirebaseData('groups/' + group_id);
        let groupData = {};
        let products = [];
        let attributes = [];
        let ads = {}
        if ( response.status === true ) {
        groupData = Object.assign({}, response.data);
        response.data.products.slice(lastItem+1, response.data.products.length).forEach( async (product_id) => {
            let productResponse = await getProductData(product_id);
            if ( productResponse.status === true ) {
            products.push(productResponse.product);
            }
        });
        let responseAttributes = await getAttributes();
        if ( responseAttributes.status === true ) {
            attributes = responseAttributes.attributes;
        }
        }
        return {
            status: true,
            products: products || [],
            ads: ads || {},
            attributes: attributes || [],
            group: groupData || {}
        }
            
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}