import React from 'react';
import NotFound from '~/components/not-found/index';
import Header from '~/components/header/index';
import Footer from '~/components/footer/index';
import Loading from '~/components/loading/loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Page404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            loaded: false
        }
    }

    componentDidMount() {
        
        this.setState({message_load: 'all_is_ready', loaded: true});
    }

    render() {
        require('./css/404.css');
        const { message_load, loaded } = this.state;
        return(
            <Loading message={message_load} loaded={loaded}>
                <div className={`page-404-container container-fluid mt-3 pt-md-4 pb-4 mb-5 show`}><NotFound button_status={true}/></div>
                <Footer />
            </Loading>
        )
    }
}

export default withRouter(Page404);